import React, { useEffect } from 'react';
import { useState } from 'react';
import { FaCheckCircle} from "react-icons/fa";
import { MdKeyboardDoubleArrowDown } from "react-icons/md";
import { Helmet } from 'react-helmet';
import CartridgeSearch from '../../components/Search/CartridgeSearch';
import './cartridges.css';

const Cartridges = () => {
  const [cartridgeBrands, setCartridgeBrands] = useState([]);
  const REACT_APP_API_SERVER = process.env.REACT_APP_API_SERVER;

  useEffect(() => {
    fetch(`${REACT_APP_API_SERVER}/cartridgeBrands`)
            .then((response) => {
              if (!response.ok) {
                throw new Error("Failed to fetch user data");
              }
              return response.json();
            })
            .then((data) => {
              setCartridgeBrands(data);
            })
            .catch((error) => {
              console.error(error);
            });
  }, []);

  const products = [
    { title: 'Officeshop ražotie kārtridži', picture: './images//OfficeShopLaser.png', apraksts: 'Augstas kvalitātes, videi draudzīgi un izdevīgi kārtridži, kas nodrošina teicamu izdruku un ilgu kalpošanas laiku. Ražoti ar rūpīgu uzmanību katram detaļai.', bonus1: ' Izdevīgi ', bonus2: 'Uzticami ', bonus3: ' Atkārtoti uzpildāmi', bonus4: 'Bezmaksas piegāde',ratings : [
      { characteristic: 'Cena', rating: 5 },
      { characteristic: 'Uzticamība', rating: 5 },
      { characteristic: 'Garantija ', rating: 5 },
      { characteristic: 'Uzpildāms', rating: 5 },
      { characteristic: 'Saderība', rating: 5 }
    ] },
    { title: 'Orģinālie kārtridži', picture: './images/OriginalLaser.png', apraksts: 'Ražotāja garantēti kārtridži, kas nodrošina nevainojamu drukas kvalitāti un optimālu printera darbību. Izvēle tiem, kuri vēlas uzticamu veiktspēju.', bonus1: 'Augstvērtīgi', bonus2: 'Uzticami', bonus3: 'Sertificēti', bonus4: 'Bezmaksas piegāde', ratings : [
      { characteristic: 'Cena', rating: 2 },
      { characteristic: 'Uzticamība', rating: 5 },
      { characteristic: 'Garantija ', rating: 5 },
      { characteristic: 'Uzpildāms', rating: 5 },
      { characteristic: 'Saderība', rating: 5 }
    ] },
    { title: 'Orģinālie tintes kārtridži', picture: './images//OriginalInk.jpg', apraksts: 'Oriģinālās tintes kasetnes nodrošina precīzus un spilgtus izdrukas rezultātus, īpaši piemērotas fotogrāfiju un grafiku drukāšanai.', bonus1: 'Augstvērtīgi', bonus2: 'Uzticami', bonus3: 'Sertificēti', bonus4: 'Bezmaksas piegāde', ratings : [
      { characteristic: 'Cena', rating: 3 },
      { characteristic: 'Uzticamība', rating: 5 },
      { characteristic: 'Garantija ', rating: 5 },
      { characteristic: 'Uzpildāms', rating: 1 },
      { characteristic: 'Saderība', rating: 5 }
    ] },
    { title: 'Alternatīvie tintes kārtridži', picture: './images//AlternativeInk.jpg', apraksts: 'Pieejamas tintes kasetnes, kas nodrošina drukas risinājumus ikdienas vajadzībām. Piemērotas lietotājiem, kuri meklē budžetam draudzīgu alternatīvu oriģinālajiem produktiem', bonus1: 'Pieejama cena', bonus2: 'Budžeta risinājums', bonus3: 'Praktiski', bonus4: 'Bezmaksas piegāde',ratings : [
      { characteristic: 'Cena', rating: 5 },
      { characteristic: 'Uzticamība', rating: 2 },
      { characteristic: 'Garantija ', rating: 1 },
      { characteristic: 'Uzpildāms', rating: 1 },
      { characteristic: 'Saderība', rating: 2 }
    ]},
  ];
  

  const renderStars = (rating) => {
    const fullStars = Math.floor(rating); // Number of full stars
    const halfStar = rating % 1 !== 0;    // Whether we need a half star
    const totalStars = 5;                 // Always render a total of 5 stars
    const emptyStars = totalStars - fullStars - (halfStar ? 1 : 0); // Remaining empty stars
  
    return (
      <>
        {Array(fullStars).fill('★').map((star, index) => (
          <span key={`full-${index}`} className="star full-star">{star}</span>
        ))}
        {halfStar && (
          <span className="star half-star">
            <span style={{ width: '50%', overflow: 'hidden', display: 'inline-block' }}>★</span>
          </span>
        )}
        {Array(emptyStars).fill('★').map((star, index) => (
          <span key={`empty-${index}`} className="star empty-star">{star}</span>
        ))}
      </>
    );
  };
  let jsonLD = {
    "@context": "https://schema.org",
    "@type": "ItemList",
    "name": `Labākie printeru kārtridži - OfficeShop.lv'}`,
    "itemListElement": cartridgeBrands.map((item, index) => ({
      "@type": "ListItem",
      "position": index + 1,
      "url": `https://www.officeshop.lv/cartridges`,
      "name": `${item.MENU_3} printeru kārtridži`,
      "image": `https://www.officeshop.lv/catImages/${item.MENU_3}.jpg`,
      "description": `${item.MENU_3} kārtridži par labākajām cenām un bezmaksas piegādi visā Latvijā. Atrodi savu ar kārtridžu meklētāju! Apskati piedāvājumu jau tagad! www.OfficeShop.lv`
    }))
  };

  return (
    <>
    <Helmet>
        <title>{`Labākie printeru kārtridži - OfficeShop.lv`}</title>
        <meta name="description" content={`HP, Epson, Canon, Kyocera, Xerox, Panasonic, Lexmark un citu zīmolu kārtridži par labākajām cenām un ar bezmaksas piegādi! | OfficeShop.lv`} />
        <meta name="robots" content="index, follow" />
        <meta name="keywords" content={`HP kārtridži, Canon kārtridži, Kyocera kārtridži, Panasonic kārtridži, Epson kārtridži, printeru kārtridži, kārtridžu uzpilde, lēti kārtridži`} />
        <meta property="og:title" content={`Labākie printeru kārtridži - OfficeShop.lv`} />
        <meta property="og:description" content={`HP, Epson, Canon, Kyocera, Xerox, Panasonic, Lexmark un citu zīmolu kārtridži par labākajām cenām un ar bezmaksas piegādi! | OfficeShop.lv`} />
        <meta property="og:url" content={`https://www.officeshop.lv/cartridges`} />
        <link rel="canonical" href={`https://www.officeshop.lv/cartridges`} />
        <meta property="og:image" content={`https://www.officeshop.lv/prodImages/logo.png`} />

        <meta property="og:image:alt" content={'Labākie printeru kārtridži - OfficeShop.lv'} />
        <meta property="og:type" content="website" />
        {jsonLD && <script type="application/ld+json">{JSON.stringify(jsonLD)}</script>}
      </Helmet>
      <div className='cartridge-search-main-container'>
        <CartridgeSearch/>
      </div>
    <div className="cartridge-container">
      {products.map((product, index) => (
        <div className="product-box" style={{ backgroundImage: `url(${product.picture})` }} key={index}>
          {product.title === 'Officeshop ražotie kārtridži' && (<img src="./images/SmartChoice.png" className="SmartChoiceLogo" alt='SmartChoice kārtridži | OfficeShop.lv' />)}
          <h3>{product.title}</h3>
          <div className='CheckContainer'>
            <div className='CheckLine'>
            <FaCheckCircle color="#8BCB15" className="CartridgePageIcon" title="Produkts ir noliktavā" />
            <span>{product.bonus1}</span>
            </div>
            <div className='CheckLine'>
            <FaCheckCircle color="#8BCB15" className="CartridgePageIcon" title="Produkts ir noliktavā" />
            <span>{product.bonus2}</span>
            </div>
            <div className='CheckLine'>
            <FaCheckCircle color="#8BCB15" className="CartridgePageIcon" title="Produkts ir noliktavā" />
            <span>{product.bonus3}</span>
            </div>
            <div className='CheckLine'>
            <FaCheckCircle color="#8BCB15" className="CartridgePageIcon" title="Produkts ir noliktavā" />
            <span>{product.bonus4}</span>
            </div>
          </div>
          <div className="ratings-container">
            {product.ratings.map((item, index) => (
              <div className="rating-row" key={index}>
                <span className="characteristic">{item.characteristic}:</span>
                <span className="stars">{renderStars(item.rating)}</span>
                <span className='starsDescription'>Apraksts</span>
              </div>
            ))}
          </div>
          <div className='cartridgeDescriptionContainer'>
          <p>{product.apraksts}</p>
          </div>
          <div className="cartridgeSelectionContainer">          
          <h4><MdKeyboardDoubleArrowDown color="#8BCB15" className="CartridgePageIcon" />Izvēlies savu kārtridžu!<MdKeyboardDoubleArrowDown color="#8BCB15" className="CartridgePageIcon" /></h4>          
          <div className="cartridgeBrandContainer">           
          {cartridgeBrands
            .filter((brand) => brand.MENU_2 === product.title)
            .sort((a, b) => a.MENU_3.localeCompare(b.MENU_3))
            .map((filteredBrand, index) => (
              <div className="brand-image" key={index}>
                <a href={`/categories/${encodeURIComponent("Printeru kārtridži").replace(
                                  /%20/g,
                                  '-'
                                )}/${product.title}/${encodeURIComponent(filteredBrand.MENU_3).replace(
                                  /%20/g,
                                  '-'
                                )}/`}>
                  <img src={`./catImages/${filteredBrand.MENU_3}.jpg`} />
                </a>
              </div>
            ))}
          </div>
          
          </div>
        </div>
      ))}
    </div>
  </>
  );
};

export default Cartridges;
