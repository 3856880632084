import { signInWithEmailAndPassword, onAuthStateChanged, signOut } from "firebase/auth";
import useFirebase from "../../firebase/usefirebase";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { GrClose } from "react-icons/gr";
import SignUp from "./SignUp";
import ResetPassword from "./ResetPassword";
import { RiContactsLine } from "react-icons/ri";


const UserHangler = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [authUser, setAuthUser] = useState(null);
  const [currentUser, setCurrentUser] = useState("");
  const [authLoading, setAuthLoading] = useState(true);
  const [wrongPasswordCount, setWrongPasswordCount] = useState(0);
  const [isActive, setIsActive] = useState(false);
  const [singUpIsActive, setSignUpIsActive] = useState(false);
  const [resetPasswIsActive, setResetPasswIsActive] = useState(false);
  const [specialOffers, setSpecialOffers] = useState(false);
  const API_SERVER = process.env.REACT_APP_API_SERVER;
  const [allSubmited, setAllSubmited] = useState(false);
  const { auth, user } = useFirebase();



  useEffect(() => {
    if (auth) {
      const unsubscribe = onAuthStateChanged(auth, (user) => {
        if (user) {
          setAuthUser(user);
          setEmail(user.email);
          setAuthLoading(false); 
        } else {
          setAuthUser(null);
          setAuthLoading(false); 
        }
      });
      return () => {
        unsubscribe();
      };
    }else {
      setAuthLoading(false); 
    }
  }, [auth]);

  useEffect(() => {
    if (authLoading) return; 
    if (authUser) {
      fetch(`${API_SERVER}/username?email=${email}`)
        .then((response) => {
          if (!response.ok) {
            throw new Error("Failed to fetch user data");
          }
          return response.json();
        })
        .then((data) => {
          setCurrentUser(data.Name);
          fetch(`${API_SERVER}/specialOffers?email=${email}`)
            .then((response) => {
              if (!response.ok) {
                throw new Error("Failed to fetch user data");
              }
              return response.json();
            })
            .then((data) => {
              data.length > 0 ? setSpecialOffers(data) : setSpecialOffers(false);
            })
            .catch((error) => console.error(error));
        })
        .catch((error) => console.error(error));
    }
  }, [authUser, email, authLoading]);

  function logIn(e) {
    e.preventDefault();
    if (wrongPasswordCount > 2) {
      setTimeout(() => {
        signInWithEmailAndPassword(auth, email, password)
          .then((user) => {
            setError(" ");
          })
          .catch((error) => {
            setError("Nepareizs e-pasts vai parole");
            setPassword("");
            setWrongPasswordCount(wrongPasswordCount + 1);
          });
      }, 4000); 
    } else {
      signInWithEmailAndPassword(auth, email, password)
        .then((user) => {
          setError(" ");
        })
        .catch((error) => {
          setError("Nepareizs e-pasts vai parole");
          setPassword("");
          setWrongPasswordCount(wrongPasswordCount + 1);
        });
    }
  }

  useEffect(() => {   
      if (validateEmail(email) && password.length >= 5) {
      setAllSubmited(true);
      } else {
      setAllSubmited(false);
      }
    
    function validateEmail(email) {
      const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return re.test(email);
    }
  }, [email, password]);

  const toggleFunction = () => {
    setIsActive(!isActive);
    setSignUpIsActive(false);
    setResetPasswIsActive(false);
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && allSubmited) {
      e.preventDefault();
      if (wrongPasswordCount > 2) {
        setTimeout(() => {
          signInWithEmailAndPassword(auth, email, password)
            .then((user) => {
              setError(" ");
            })
            .catch((error) => {
              setError("Nepareizs e-pasts vai parole");
              setPassword("");
              setWrongPasswordCount(wrongPasswordCount + 1);
            });
        }, 4000); 
      } else {
        signInWithEmailAndPassword(auth, email, password)
          .then((user) => {
            setError(" ");
          })
          .catch((error) => {
            setError("Nepareizs e-pasts vai parole");
            setPassword("");
            setWrongPasswordCount(wrongPasswordCount + 1);
          });
      }
    }
};

    return (
        <div className="dropdown-container" >
          {authUser && !authLoading  && currentUser !==  "" ? (
            <div>
            <div className="userNameWrapper">
              <div onClick={toggleFunction} className="userName" >
                <p className="contact-text">Sveiki, {currentUser.endsWith("s") ? currentUser.slice(0, -1) : currentUser}</p>
              </div>
              <div onClick={toggleFunction} className="main-line-item">
                <RiContactsLine className="contact-icon" />
              </div>
            </div>
              {isActive ? <div className="Cover-container"> </div>: null}
              <div className={`slide-in ${isActive ? 'active' : ''}`}>
                <div className='virsraksts-cartridge'>
                <h2>Mans Profils</h2>
                <GrClose onClick={toggleFunction}  className='icon'>Aizvērt</GrClose>
                </div>
                <div className="input-msg">
                  <Link to="/user-profile" className="profile-data" onClick={()=>setIsActive(false)}>Mani dati</Link>
                  <Link to="/favorites" className="profile-data" onClick={()=>setIsActive(false)}>Mani Favorīti</Link>
                {specialOffers ? (
                  <Link to="/special-offers" className="profile-data" onClick={() => setIsActive(false)}>
                    Mani īpašie piedāvājumi
                  </Link>
                ) : null}
                  <Link to="/order-history"className="profile-data" onClick={()=>setIsActive(false)} >Mana pasūtījumu vēsture</Link>
                  
                  <div/>
                  <button className="logIn" onClick={() => {    
                    setIsActive(false);                                    
                    window.location.href = "/";                    
                    signOut(auth, email);                    
                  }}
                  >Iziet</button>
                </div>
              </div>
            </div>
          ) : (
            <div>
              <div onClick={toggleFunction} className="main-line-item">
                {!authUser && !authLoading && currentUser ===  "" ? (
                <p className="contact-text">Ienākt/Reģistrēties</p>
                ): null}
                <RiContactsLine className="contact-icon" />                
              </div> 
              {isActive ? <div className="Cover-container"> </div>: null}
              <div className={`slide-in ${isActive ? 'active' : ''}`}>              
                  
                    <div className='virsraksts-cartridge'>
                      {singUpIsActive? <h2>Reģistrēties</h2>: resetPasswIsActive ? <h2>Paroles atjaunošana</h2>: <h2>Ienākt</h2>}
                      <GrClose onClick={toggleFunction} className='icon'>Aizvērt</GrClose>
                    </div>
                    {singUpIsActive ?
              <SignUp />
              :
              resetPasswIsActive ?
              <ResetPassword />
              :
                    
                    <div className="input-msg" >
                      <form onKeyDown={handleKeyPress}>
                      {error && <p className="error-msg">{error}</p>}
                      <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="E-pasts"/>
                      <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} placeholder="Parole"/>
                      {error && <div onClick={() =>setResetPasswIsActive(true)} className="link">Aizmirsi savu paroli ?</div>}
                      <div className="logInWrapper">
                      {allSubmited ? <button className="logIn" onClick={logIn}>Ienākt</button> : <button className="logIn" onClick={(e)=> {e.preventDefault()}} style={{ color: "black", backgroundColor: "#e8e8e8" }}>Ienākt</button>}
                      </div>
                      </form>
                      <div className="seperator"></div>
                      <div className="new-profile-text">Tev vēl nav sava profila?</div>
                      <button
                      className="logIn"
                      onClick={() => setSignUpIsActive(true)}
                      >Reģistrēties</button>
                    </div>
                  
                }
              </div>
            </div>
          )}
        </div>
      );
    };

export default UserHangler;
