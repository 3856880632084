import React, { useState } from 'react';
import useFirebase from "../../firebase/usefirebase";
import { sendPasswordResetEmail } from 'firebase/auth';


const PasswordReset = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const { auth } = useFirebase();

  const handleReset = (e) => {
    sendPasswordResetEmail(auth, email)
      .then(() => {
        setMessage(`Uz Jūsu epastu: ${email} tika nosūtīta paroles atjaunošanas instrukcija. Lūdzu, pārbaudiet savu epastu! (Arī spam mapē)`);
      })
      .catch((error) => {
        if (error.code === 'auth/user-not-found') {
          setMessage(`Lietotājs ar epastu ${email} nav reģistrēts. Lūdzu pārbaudiet ievadīto epastu.`);
        } else {
          setMessage(`Kļūda: ${error.message}`);
        }
        console.error(error.message);
      });
  };
  return (
    <div className="input-msg">
      <p className="error-msg">{message}</p>
      <input
        type="email"
        placeholder="Jūsu reģistrācijas epasts"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      <button onClick={handleReset} className="logIn">Atjaunot paroli</button>
      
    </div>
  );
};

export default PasswordReset;