import { useEffect, useState } from 'react';
import { BsEnvelope } from "react-icons/bs";
import { GrClose } from "react-icons/gr";
import useNotification from "../Notifications/notificationHandler"






function InputForm() {
    const [isActive, setIsActive] = useState(false);
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");
    const [allSubmited, setAllSubmited] = useState(false);
    const showNotification = useNotification();


    const API_SERVER = process.env.REACT_APP_API_SERVER;


    const toggleFunction = () => {
        if (isActive) {
            setName("");
            setEmail("");
            setMessage("");
        }
        setIsActive(!isActive);
    };

    function sendMessage(e) {
        e.preventDefault();
        setAllSubmited(false);
        fetch(`${API_SERVER}/sendEmailfromWeb`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                name: name,
                email: email,
                message: message
            })
        })
            .then(response => {
                if (response.status === 200) {
                    setName("");
                    setEmail("");
                    setMessage("");
                    setIsActive(false);
                    showNotification('Jūsu Ziņa ir nosūtīta!');
                }
                return response.json();
            })
            .catch((error) => console.log(error));

    }

    useEffect(() => {
        if (name && email && message) {
            setAllSubmited(true);
        } else {
            setAllSubmited(false);
        }
    }, [name, email, message]);

    useEffect(() => {
        if (name.length>2 &&
            email.includes('@') && email.includes('.') &&
            message !== null && message !== "") {
            setAllSubmited(true);
        } else {
            setAllSubmited(false);
        }
    }, [name, email, message]);


    return (
        <>
            <div onClick={toggleFunction} className="main-line-item">
                <BsEnvelope
                    className='contact-icon'
                />
            </div>
            {isActive ? <div className="Cover-container"> </div> : null}
            <div className={`slide-in ${isActive ? 'active' : ''}`}>
                <div className='virsraksts-cartridge'>
                    <h2>Sūti mums ziņu!</h2>
                    <GrClose onClick={toggleFunction} className='icon'>Aizvērt</GrClose>
                </div>
                <form onSubmit={sendMessage}>
                    <div className="input-msg2">
                        <input type="text" value={name} placeholder="Vārds" onChange={(e) => setName(e.target.value)} required />
                    </div>
                    <div className="input-msg2">
                        <input type="email" placeholder="E-pasts" value={email} onChange={(e) => setEmail(e.target.value)} required />
                    </div>
                    <div className="input-msg3">
                        <input type="text" placeholder="Ziņa" value={message} onChange={(e) => setMessage(e.target.value)} required />
                    </div>
                    {allSubmited ?
                        <div className="input-msg2">
                            <button className="logIn" type="submit">Sūtīt</button>
                        </div>
                        :
                        <div className="input-msg2">
                            <button className="logIn" style={{ color: "black", backgroundColor: "#e8e8e8" }}>Sūtīt</button>
                        </div>
                    }
                </form>
            </div>
        </>
    )
}

export default InputForm;