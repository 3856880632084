import { useEffect, useState } from 'react';
import useNotification from "../Notifications/notificationHandler"






function InputFormCartridgeSearch(printer) {    
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState(`Labdien,\n${printer.printer} printerim kārtridži netika atrasti meklētājā.\nLūdzu sagatavot man kārtridžu piedāvājumu šim printerim`);
    const [allSubmited, setAllSubmited] = useState(false);
    const showNotification = useNotification();

    const API_SERVER = process.env.REACT_APP_API_SERVER;

    function sendMessage(e) {
        e.preventDefault();
        setAllSubmited(false);
        fetch(`${API_SERVER}/sendEmailfromWeb`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                name: name,
                email: email,
                message: message
            })
        })
            .then(response => {
                if (response.status === 200) {
                    setName("");
                    setEmail("");
                    setMessage("");
                    showNotification('Jūsu Ziņa ir nosūtīta!');
                }
                return response.json();
            })
            .catch((error) => console.log(error));

    }

    useEffect(() => {
        if (name && email && message) {
            setAllSubmited(true);
        } else {
            setAllSubmited(false);
        }
    }, [name, email, message]);

    useEffect(() => {
        if (name.length>2 &&
            email.includes('@') && email.includes('.') &&
            message !== null && message !== "") {
            setAllSubmited(true);
        } else {
            setAllSubmited(false);
        }
    }, [name, email, message]);


    return (
        <>
                <form onSubmit={sendMessage}>
                    <div className="input-msg2">
                        <input type="text" value={name} placeholder="Vārds" onChange={(e) => setName(e.target.value)} required />
                    </div>
                    <div className="input-msg2">
                        <input type="email" placeholder="E-pasts" value={email} onChange={(e) => setEmail(e.target.value)} required />
                    </div>
                    <div className="input-msg3">
                        <textarea  type="text" placeholder="Ziņa" value={message} onChange={(e) => setMessage(e.target.value)} required />
                    </div>
                    {allSubmited ?
                        <div className="input-msg2">
                            <button className="logIn" type="submit">Sūtīt</button>
                        </div>
                        :
                        <div className="input-msg2">
                            <button className="logIn" style={{ color: "black", backgroundColor: "#e8e8e8" }}>Sūtīt</button>
                        </div>
                    }
                </form>
        </>
    )
}

export default InputFormCartridgeSearch;