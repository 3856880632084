import React, { useEffect, useState } from 'react';
import ProductCard from '..//productCard/ProductCard.js';
import useFirebase from "../../firebase/usefirebase";
import { onAuthStateChanged } from "firebase/auth";
import './search.css';
import { set } from 'date-fns';

const CartridgeSearchResults = () => {
  const [searchResults, setSearchResults] = useState([]);
  const [printerModels, setPrinterModels] = useState([]);
  const { auth, user } = useFirebase();
    const [email, setEmail] = useState('');
    const [favorites, setFavorites] = useState([]);
    const [authLoading, setAuthLoading] = useState(true);
    const [productsPerPage, setProductsPerPage] = useState(15);
    const [showMobileAdd, setShowMobileAdd] = useState(false);
    const REACT_APP_API_SERVER = process.env.REACT_APP_API_SERVER;


  useEffect(() => {
    const results = JSON.parse(localStorage.getItem('cartridges'));
    if (results) {
      setSearchResults(results);        
    }
    const printer = JSON.parse(localStorage.getItem('printerModel'));
    if (printer) {
      setPrinterModels(printer);
    }
    
      const updateProductsPerPage = () => {
        if (window.innerWidth < 768) {
          setProductsPerPage(16);
          setShowMobileAdd(true);
        } else {
          setProductsPerPage(15);
          setShowMobileAdd(false);
        }
      };
      updateProductsPerPage();
      window.addEventListener('resize', updateProductsPerPage);
      window.scrollTo(0, 0);
      if (auth) {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
          if (user) {
            setEmail(user.email);
            fetch(`${REACT_APP_API_SERVER}/favorites?Email=${user.email}`)
              .then((response) => {
                if (!response.ok) {
                  throw new Error("Failed to fetch user data");
                }
                return response.json();
              })
              .then((data) => {
                const favoritesString = data[0]?.Favorites || '';
                const favoritesArray = favoritesString.split(",").filter(item => item.trim() !== '');
                setFavorites(favoritesArray);
              })
              .catch((error) => console.error(error))
              .finally(() => setAuthLoading(false));
          } else {
            setAuthLoading(false);
          }
        });
  
        return () => {
          window.removeEventListener('resize', updateProductsPerPage);
          unsubscribe();
        };
      } else {
        setAuthLoading(false);
      }
    

  }, []);

  const images = [
    {
      name: "Officeshop ražotie kārtridži",
      image: "./images/OfficeShopLaser.png",
    },
    {
      name: "Orģinālie kārtridži",
      image: "./images/OriginalLaser.png",
    },
    {
      name: "Alternatīvie tintes kārtridži",
      image: "./images/AlternativeInk.jpg",
    },
    {
      name: "Orģinālie tintes kārtridži",
      image: "./images/OriginalInk.jpg",
    }
  ];

    return (
      <>
      {searchResults.length > 0 && <p className='PrinterNameSearchResults'>Kārtridži <strong>{printerModels}</strong> printerim</p>}
      <div className='CartridgeSearchResults'>
        {searchResults.length === 0 ? (
          <p>No results found</p>
        ) : (
          
          
          Array.from(new Set(searchResults.map((item) => item.MENU_2)))
            .sort((a, b) => a.localeCompare(b))
            .map((menu2) => {
              const imageObj = images.find(img => img.name === menu2);
              const backgroundImage = imageObj ? imageObj.image : '';
              return (
                <>                               
                  <div className="CartridgeResultsContainer" style={{ backgroundImage: `url(${backgroundImage})` }}>
                    <p>{menu2}</p>
                    {menu2 === 'Officeshop ražotie kārtridži' && (<img src="./images/SmartChoice.png" className="SmartChoiceLogoSearchResults" alt='SmartChoice kārtridži | OfficeShop.lv' />)}
                    <div className='CartridgeSearchContainerCategrory'>                                
                      {searchResults
                        .filter((item) => item.MENU_2 === menu2)
                        .map((item) => (
                          <div className='ShowCartrideCard'>                                                                              
                            <ProductCard
                              name={item.NAME}
                              price={item.PRICE}
                              quantity={item.QTY}
                              minorder={item.MINORDER}
                              discount={item.DISCOUNT}
                              link={item.MENU_3 === 'N/A'
                                ? `/categories/${encodeURIComponent(item.MENU_1).replace(
                                  /%20/g,
                                  '-'
                                )}/${encodeURIComponent(item.MENU_2).replace(
                                  /%20/g,
                                  '-'
                                )}/${item.SKU}`
                                : `/categories/${encodeURIComponent(item.MENU_1).replace(
                                  /%20/g,
                                  '-'
                                )}/${encodeURIComponent(item.MENU_2).replace(
                                  /%20/g,
                                  '-'
                                )}/${encodeURIComponent(item.MENU_3).replace(
                                  /%20/g,
                                  '-'
                                )}/${item.SKU}`}
                              image={item.MENU_2 === "Officeshop ražotie kārtridži" ? `/prodImages/Small/Officeshop Cartridges (Small)/cartridges (Small).jpg` : item.PICTURE && item.PICTURE.startsWith('http') ? item.PICTURE : item.PICTURE ? `/prodImages/Small/${item.SKU} (Small)/${item.SKU} (Small).${item.PICTURE}` : `/prodImages/Small/logo (Small)/logo (Small).png`}
                              sku={item.SKU}
                              fav={favorites.includes(item.SKU) ? 'yes' : 'no'}
                              // onDeleteFav={() => { updateFavoritesAfterDelete(); }}
                              userEmail={email}
                            />
                          </div>
                        ))}
                    </div>
                  </div>
                </>
              );
            })
        )}
      </div>
      </>
    );
};

export default CartridgeSearchResults;