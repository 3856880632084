import React, { useEffect } from 'react';
import { useState } from 'react';
import { FaCheckCircle} from "react-icons/fa";
import { MdKeyboardDoubleArrowDown } from "react-icons/md";
import { Helmet } from 'react-helmet';
import './ribbon.css'

const Ribbon = () => {
  const [ribbons, setRibbons] = useState([]);
  const REACT_APP_API_SERVER = process.env.REACT_APP_API_SERVER;

  useEffect(() => {
    fetch(`${REACT_APP_API_SERVER}/ribbons`)
            .then((response) => {
              if (!response.ok) {
                throw new Error("Failed to fetch user data");
              }
              return response.json();
            })
            .then((data) => {
              setRibbons(data);
            })
            .catch((error) => {
              console.error(error);
            });
  }, []);

  const products = [
    { title: 'Resin', picture: './images//Ribbon1.png', apraksts: 'Resin lentes ir izturīgas pret augstu temperatūru, mitrumu, ķīmiskām vielām un berzi. Tās nodrošina ilgstošu noturību uz dažādiem materiāliem, piemēram, poliesteriem un poliimīda etiķetēm. Piemērotas automobiļu, elektronikas un farmācijas nozarēm, kur etiķetēm jāiztur sarežģīti apstākļi un jābūt lasāmām ilgstoši.', bonus1: 'Īpašai drukas noturībai', bonus2: 'IN/OUT uztinums ', bonus3: 'Vairumā atlaides', bonus4: 'Bezmaksas piegāde',ratings : [
      { characteristic: 'Cena', rating: 3 },
      { characteristic: 'Drukas kvalitāte', rating: 5 },
      { characteristic: 'Drukas noturība ', rating: 5 },
      { characteristic: 'Drukas ātrums', rating: 5 },
      { characteristic: 'Saderība', rating: 5 }
    ] },
    { title: 'Wax Resin', picture: './images/Ribbon2.png', apraksts: 'Wax/Resin lentes ir daudzpusīgs risinājums drukai uz papīra un sintētiskiem materiāliem. Tās piedāvā izturību pret izplūšanu, skrāpējumiem un mēreniem ķīmiskajiem un vides faktoriem. Ideāli piemērotas ilgstošām etiķetēm, piemēram, aktīvu marķēšanai, veselības aprūpei un produktu marķēšanai tirdzniecībā.', bonus1: 'Teicama drukas noturība', bonus2: 'IN/OUT uztinums ', bonus3: 'Vairumā atlaides', bonus4: 'Bezmaksas piegāde', ratings : [
      { characteristic: 'Cena', rating: 4 },
      { characteristic: 'Drukas kvalitāte', rating: 5 },
      { characteristic: 'Drukas noturība ', rating: 4 },
      { characteristic: 'Drukas ātrums', rating: 5 },
      { characteristic: 'Saderība', rating: 5 }
    ] },
    { title: 'Wax', picture: './images//Ribbon3.png', apraksts: 'Wax kopējošās lentes ir ekonomisks risinājums augstas kvalitātes drukai uz papīra etiķetēm. Tās nodrošina lielisku drukas kvalitāti standarta svītru kodiem, tekstam un grafikai. Ideāli piemērotas lietošanai tirdzniecībā, loģistikā un krājumu pārvaldībā, kad nav nepieciešama augsta izturība pret vides apstākļiem.', bonus1: 'Ekonomiskai apdrukai', bonus2: 'IN/OUT uztinums ', bonus3: 'Vairumā atlaides', bonus4: 'Bezmaksas piegāde', ratings : [
      { characteristic: 'Cena', rating: 5 },
      { characteristic: 'Drukas kvalitāte', rating: 4 },
      { characteristic: 'Drukas noturība ', rating: 3 },
      { characteristic: 'Drukas ātrums', rating: 5 },
      { characteristic: 'Saderība', rating: 5 }
    ] }    
  ];
  

  const renderStars = (rating) => {
    const fullStars = Math.floor(rating); // Number of full stars
    const halfStar = rating % 1 !== 0;    // Whether we need a half star
    const totalStars = 5;                 // Always render a total of 5 stars
    const emptyStars = totalStars - fullStars - (halfStar ? 1 : 0); // Remaining empty stars
  
    return (
      <>
        {Array(fullStars).fill('★').map((star, index) => (
          <span key={`full-${index}`} className="star full-star">{star}</span>
        ))}
        {halfStar && (
          <span className="star half-star">
            <span style={{ width: '50%', overflow: 'hidden', display: 'inline-block' }}>★</span>
          </span>
        )}
        {Array(emptyStars).fill('★').map((star, index) => (
          <span key={`empty-${index}`} className="star empty-star">{star}</span>
        ))}
      </>
    );
  };

  const getNextDayDate = () => {
    const now = new Date();
    const nextDay = new Date(now);
    nextDay.setDate(now.getDate() + 1); 
    nextDay.setHours(23, 59, 0); 
    return nextDay.toISOString().split('T')[0] + 'T23:59:00'; 
  };

  let jsonLD = {
    "@context": "https://schema.org",
    "@type": "ItemList",
    "name": `Premium kvalitātes karobna lentas | OfficeShop.lv`,
    "itemListElement":
      ribbons.map((product, index) => ({        
      "@type": "Product",
      "name": `${product.MENU_2} ${product.NAME} ribbon`,
      "image": "https://www.officeshop.lv/prodImages/Small/Ribbon%20(Small)/ribbon%20(Small).jpg",
      "description":`${product.MENU_2} ${product.NAME} Premium kvalitātes karbona lenta par labākajām cenām, ar bezmaksas piegādi. Ir noliktavā. | www.OfficeShop.lv`,
      "brand": "OfficeShop",
      "offers": {
        "@type": "Offer",
        "price": product.PRICE,
        "priceCurrency": "EUR",
        'priceValidUntil': getNextDayDate(),
        "availability": "InStock",
        "hasMerchantReturnPolicy": {
          "@type": "MerchantReturnPolicy",
          "returnPolicyCategory": "https://schema.org/ReturnAllowed",
          "applicableCountry": "LV",
          "returnPolicySeasonalOverride": "false",
          "merchantReturnDays": 14
        },
        "shippingDetails": {  
          "@type": "OfferShippingDetails",
          "shippingDestination": {
            "@type": "DefinedRegion",
            "name": "Latvia"
          },
          "deliveryTime": {
            "@type": "ShippingDeliveryTime",
            "businessDays": 3
          }
        }
      },
      "url": "https://www.officeshop.lv/thermalTransfer"     
    }))
    
  };

  return (
    <>
    <Helmet>
        <title>{`Premium kvalitātes karobna lentas | OfficeShop.lv`}</title>
        <meta name="description" content={`Wax, Wax/Resin, Resin kopējošās lentas par labākajām cenām, ar bezmaksas piegādi | OfficeShop.lv`} />
        <meta name="robots" content="index, follow" />
        <meta name="keywords" content={`Karbona lentas, kopējošās lentas, wax ribbon, wax/resin ribbon, resin ribbon, thermal transfer ribbon, 110x300 wax/resin, 160x300 wax, 50x300 wax/resin`} />
        <meta property="og:title" content={`Premium kvalitātes karobna lentas | OfficeShop.lv`} />
        <meta property="og:description" content={'Wax, Wax/Resin, Resin kopējošās lentas par labākajām cenām, ar bezmaksas piegādi | OfficeShop.lv'} />
        <meta property="og:url" content={`https://www.officeshop.lv/thermalTransfer`} />
        <link rel="canonical" href={`https://www.officeshop.lv/thermalTransfer`} />
        <meta property="og:image" content={`https://www.officeshop.lv/prodImages/Small/Ribbon%20(Small)/ribbon%20(Small).jpg`} />
        <meta property="og:image:alt" content={`Premium kvalitātes karobna lentas | OfficeShop.lv`} />
        <meta property="og:type" content="website" />
        {jsonLD && <script type="application/ld+json">{JSON.stringify(jsonLD)}</script>}
      </Helmet>
    <div className="ribbon-container" >
      {products.map((product, index) => (
        <div className="product-box-ribbon" style={{ backgroundImage: `url(${product.picture})`}} key={index}>
          {product.title === 'Wax Resin' && (<img src="./images/SmartChoice.png" className="SmartChoiceLogo" alt='SmartChoice kārtridži | OfficeShop.lv' />)}
          <h3>{product.title} pārneses lentes</h3>
          <div className='CheckContainer'>
            <div className='CheckLine'>
            <FaCheckCircle color="#8BCB15" className="CartridgePageIcon" title="Produkts ir noliktavā" />
            <span>{product.bonus1}</span>
            </div>
            <div className='CheckLine'>
            <FaCheckCircle color="#8BCB15" className="CartridgePageIcon" title="Produkts ir noliktavā" />
            <span>{product.bonus2}</span>
            </div>
            <div className='CheckLine'>
            <FaCheckCircle color="#8BCB15" className="CartridgePageIcon" title="Produkts ir noliktavā" />
            <span>{product.bonus3}</span>
            </div>
            <div className='CheckLine'>
            <FaCheckCircle color="#8BCB15" className="CartridgePageIcon" title="Produkts ir noliktavā" />
            <span>{product.bonus4}</span>
            </div>
          </div>
          <div className="ratings-container">
            {product.ratings.map((item, index) => (
              <div className="rating-row" key={index}>
                <span className="characteristic-long">{item.characteristic}:</span>
                <span className="stars">{renderStars(item.rating)}</span>
                <span className='starsDescription'>Apraksts</span>
              </div>
            ))}
          </div>
          <div className='cartridgeDescriptionContainer'>
          <p className='RibbonDescription'>{product.apraksts}</p>
          </div>
          <div className="cartridgeSelectionContainer">          
          <h4><MdKeyboardDoubleArrowDown color="#8BCB15" className="CartridgePageIcon" />Izvēlies atbilstošo izmēru<MdKeyboardDoubleArrowDown color="#8BCB15" className="CartridgePageIcon" /></h4>          
          <div className="cartridgeBrandContainer">           
          {ribbons
            .filter((brand) => brand.MENU_2 === product.title)
            .sort((a, b) => a.PRICE - b.PRICE) 
            .map((filteredBrand, index) => (
              <div className="brand-image" key={index} onClick={() => window.location.href = `/categories/${encodeURIComponent("Kopējošās lentas (ribbon)").replace(
                /%20/g,
                '-'
              )}/${encodeURIComponent(filteredBrand.MENU_2).replace(
                /%20/g,
                '-'
              )}/${filteredBrand.SKU}`}>
               
                  <div className='RibbonContainer'>
                    <span className='RibbonName'>{filteredBrand.NAME}</span>
                    <div className="price">
                    €<span className="euros">{Math.floor(filteredBrand.PRICE)}</span><span className="cents">{Math.round((filteredBrand.PRICE - Math.floor(filteredBrand.PRICE)) * 100).toString().padStart(2, '0')}</span> 
  </div>
                    
                  </div>
                
              </div>
            ))}
          </div>
          
          </div>
        </div>
      ))}
    </div>
  </>
  );
};

export default Ribbon;
