import { onAuthStateChanged } from "firebase/auth";
import useFirebase from "../../firebase/usefirebase";
import React, {useState, useEffect} from "react";
import '..//..//Pages/Checkout/checkout.css';
import useNotification  from "../Notifications/notificationHandler"
import { Helmet } from "react-helmet";

const UserProfile = () => {
    const [email, setEmail] = useState("");
    const [customerType, setCustomerType] = useState("private");
    const [name, setName] = useState("");
    const [surname, setSurname] = useState("");
    const [companyName, setCompanyName] = useState("");
    const [vatNumber, setVatNumber] = useState("");
    const [phone, setPhone] = useState("");//Kontakttālrunis
    const [city, setCity] = useState(null); //Piegādes pilseta	
    const [street, setStreet] = useState(null);//Piegādes adrese
    const [streetNo, setStreetNo] = useState(null);//Piegādes adrese [numurs
    const [flatNo, setFlatNo] = useState(null);//Piegādes adrese [dzīvoklis
    const [postCode, setPostCode] = useState(null);//Piegaades pasta indekss
    const [regNumber, setRegNumber] = useState("");
    const [legalAddress, setLegalAddress] = useState("");
    const [authUser, setAuthUser] = useState("");  
    const [authLoading, setAuthLoading] = useState(true);  
    const [isLoading, setIsLoading] = useState(true);
    const showNotification = useNotification();
    const { auth, user } = useFirebase();
    const API_SERVER = process.env.REACT_APP_API_SERVER;


    useEffect(() => {
        if (auth) {
            const unsubscribe = onAuthStateChanged(auth, (user) => {
                if (user) {
                    setAuthUser(user);
                    setEmail(user.email);
                    fetch(`${API_SERVER}/username?email=${user.email}`)
                    .then((response) => {
                        if (!response.ok) {
                            throw new Error("Failed to fetch user data");
                        }
                        return response.json();
                    })
                    .then((data) => {
                        setName(data.Name);
                        setSurname(data.Surname);
                        setPhone(data.Phone);
                        setCity(data.City);
                        setStreet(data.Street);
                        setStreetNo(data.StreetNo);
                        setFlatNo(data.FlatNo);
                        setPostCode(data.PostCode);
                        setCustomerType(data.Type);
                        if (data.Type === "company") {
                            setCompanyName(data.CompanyName);
                            setVatNumber(data.VAT);
                            setRegNumber(data.RegNumber);
                            setLegalAddress(data.LegalAddress);
                        }
                        setIsLoading(false);
                    })
                    .catch((error) => console.error(error))
                    .finally(() => setAuthLoading(false)); 
                } else {
                    setAuthUser(null);
                    setAuthLoading(false); 
                }
            });
            return () => {
                unsubscribe();
            };
        } else {
            setAuthLoading(false); 
        }
    }, [API_SERVER, auth]);

    useEffect(() => {
        if (authLoading) return; 
        if (authUser) {
            fetch(`${API_SERVER}/username?email=${email}`)
                .then((response) => {
                    if (!response.ok) {
                        throw new Error("Failed to fetch user data");
                    }
                    return response.json();
                })
                .then((data) => {
                    setName(data.Name);
                    setSurname(data.Surname);
                    setPhone(data.Phone);
                    setCity(data.City);
                    setStreet(data.Street);
                    setStreetNo(data.StreetNo);
                    setFlatNo(data.FlatNo);
                    setPostCode(data.PostCode);
                    setCustomerType(data.Type);
                    if (data.Type === "company") {
                        setCompanyName(data.CompanyName);
                        setVatNumber(data.VAT);
                        setRegNumber(data.RegNumber);
                        setLegalAddress(data.LegalAddress);
                    }
                    setIsLoading(false);
                })
                .catch((error) => console.error(error));
        }
    }, [authUser, authLoading]);
    

    function updateUserData(e) {
        e.preventDefault();        
               
                fetch(`${API_SERVER}/updateuser`, {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        name: name,
                        surname: surname,
                        type: customerType,
                        email: email,
                        companyName: companyName,
                        VAT: vatNumber,
                        Phone: phone,
                        city: city,
                        street: street,
                        streetNo: streetNo,
                        flatNo: flatNo,
                        postCode: postCode,
                        RegNumber: regNumber,
                        legalAddress: legalAddress,
                    })
                })
                    .catch((error) => console.log(error));
                    showNotification('Dati atjaunoti!');
            }
            
    

    return (
        <div>
            <Helmet>
                <title>Mans profils | Officeshop.lv</title>
                <meta name="description" content="Apskati un labo savu kontaktinformāciju vietnē Officeshop.lv" />
                <meta name="robots" content="noindex, nofollow" />
                <meta property="og:title" content="Mans profils | Officeshop" />
                <meta property="og:description" content="Apskati un labo savu kontaktinformāciju vietnē Officeshop.lv" />
                <meta property="og:url" content="https://www.officeshop.lv/user-profile" />
                <meta property="og:image" content="https://www.officeshop.lv/prodImages/logo.png" />
                <meta property="og:type" content="website" />
            </Helmet>  
            {isLoading ? <img src='/images/loading.gif' alt='loading' /> :         
              <form onSubmit={updateUserData}>
                                <h1 className="checkout-title">Mani dati</h1>
                                <p className="profile-text">Vārds</p>
                                <div className="input-msg2">
                                    <input type="text"  value={name} onChange={(e)=> setName(e.target.value)} required  />
                                </div>
                                <p className="profile-text">Uzvārds</p>
                                <div className="input-msg2">
                                    <input type="text" value={surname} onChange={(e)=> setSurname(e.target.value)} required />
                                </div>
                                <p className="profile-text">Kontakttālrunis</p>
                                <div className="input-msg2">
                                    <input type="text" value={phone} onChange={(e)=> setPhone(e.target.value)} required />
                                </div>
                                {customerType === "private" && (
                                    <div>
                                        <h1 className="checkout-title">Piegādes adrese</h1>
                                        <p className="checkout-text">Šī adrese tiks automātiski norādīta, ja izvēlēsieties piegādi ar kurjeru uz adresi!</p>
                                        <p className="profile-text">Pilsēta</p>
                                        <div className="input-msg2">
                                        <input type="text" placeholder="piem. Rīga" value={city} onChange={(e)=> setCity(e.target.value)}  />
                                        </div>
                                        <p className="profile-text">Iela</p>
                                        <div className="input-msg2">
                                            <input type="text" placeholder="piem. Brīvības iela " value={street} onChange={(e)=> setStreet(e.target.value)}  />
                                        </div>
                                        <p className="profile-text">Mājas Nr.</p>
                                        <div className="input-msg2">
                                            <input type="text" placeholder="piem. 29" value={streetNo} onChange={(e)=> setStreetNo(e.target.value)}  />
                                        </div>
                                        <p className="profile-text">Dzīvoklis</p>
                                        <div className="input-msg2">
                                            <input type="text" placeholder="piem. 1" value={flatNo} onChange={(e)=> setFlatNo(e.target.value)}  />
                                        </div>
                                        <p className="profile-text">Pasta indekss</p>
                                        <div className="input-msg2">
                                            <input type="text" placeholder="piem. LV-1003" value={postCode} onChange={(e)=> setPostCode(e.target.value)}  />
                                        </div>
                                    </div>
                                )}
                                {customerType === "company" && (
                                    <div>
                                        <h1 className="checkout-title">Piegādes adrese</h1>
                                        <p className="checkout-text">Šī adrese tiks automātiski norādīta, ja izvēlēsieties piegādi ar kurjeru uz adresi!</p>
                                        <p className="profile-text">Pilsēta</p>
                                        <div className="input-msg2">
                                        <input type="text" placeholder="piem. Rīga" value={city} onChange={(e)=> setCity(e.target.value)}  />
                                        </div>
                                        <p className="profile-text">Iela</p>
                                        <div className="input-msg2">
                                            <input type="text" placeholder="piem. Brīvības iela " value={street} onChange={(e)=> setStreet(e.target.value)}  />
                                        </div>
                                        <p className="profile-text">Mājas Nr.</p>
                                        <div className="input-msg2">
                                            <input type="text" placeholder="piem. 29" value={streetNo} onChange={(e)=> setStreetNo(e.target.value)}  />
                                        </div>
                                        <p className="profile-text">Dzīvoklis</p>
                                        <div className="input-msg2">
                                            <input type="text" placeholder="piem. 1" value={flatNo} onChange={(e)=> setFlatNo(e.target.value)}  />
                                        </div>
                                        <p className="profile-text">Pasta indekss</p>
                                        <div className="input-msg2">
                                            <input type="text" placeholder="piem. LV-1003" value={postCode} onChange={(e)=> setPostCode(e.target.value)}  />
                                        </div>
                                        <h1 className="checkout-title">Uzņēmuma rekvizīti</h1>
                                        <p className="profile-text"> Uzņēmuma nosaukums</p>
                                        <div className="input-msg2">
                                            <input type="text" value={companyName} onChange={(e)=> setCompanyName(e.target.value)} required  />
                                        </div>
                                        <p className="profile-text"> Uzņēmuma reģistrācijas Nr.</p>
                                        <div className="input-msg2">
                                            <input type="text" value={regNumber} onChange={(e)=> setRegNumber(e.target.value)} required />
                                        </div>
                                        <p className="profile-text">PVN reģistrācijas Nr.</p>
                                        <div className="input-msg2">
                                            <input type="text" value={vatNumber} onChange={(e)=> setVatNumber(e.target.value)}/>
                                        </div>
                                         <p className="profile-text">Juridiskā adrese</p>
                                        <div className="input-msg2">
                                           <input type="text" value={legalAddress} onChange={(e)=> setLegalAddress(e.target.value)} required />
                                        </div>
                                    </div>
                                )}
                            <div className="button-container">
                                <button className="logIn" type="submit">Atjaunot</button>
                            </div>
            </form>
        }
        </div>
    )
}

export default UserProfile;
