

import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import SuccessNotification from './notifications';

const useNotification = () => {
    const [notification, setNotification] = useState(null);

    const showNotification = (message) => {
        setNotification({ message });
    };

    const hideNotification = () => {
        setNotification(null);
    };

    useEffect(() => {
        if (notification) {
            const root = document.createElement('div');
            root.id = 'notification-root';
            document.body.appendChild(root);

            ReactDOM.render(
                <SuccessNotification
                    message={notification.message}
                    onClose={hideNotification}
                />,
                root
            );

            return () => {
                ReactDOM.unmountComponentAtNode(root);
                document.body.removeChild(root);
            };
        }
    }, [notification]);

    return showNotification;
};

export default useNotification;