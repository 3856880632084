const API_SERVER = process.env.REACT_APP_API_SERVER;

const GetProdOrCat = (menu1, menu2, menu3, product, prevMenu) => {
    return new Promise((resolve, reject) => {
        fetch(`${API_SERVER}/productInfo?menu1=${menu1}&menu2=${menu2}&menu3=${menu3}&product=${product}&prevMenu=${prevMenu}`)
            .then(response => {
                if (!response.ok) {
                    throw new Error("Failed to fetch data");
                }
                return response.json();
            })
            .then(data => {
                resolve(data);
            })
            .catch(error => {
                reject(error);
            });
    });
}

const GetItems = (menu1, menu2, menu3, product) => {
    return new Promise((resolve, reject) => {
        fetch(`${API_SERVER}/GetItems?menu1=${menu1}&menu2=${menu2}&menu3=${menu3}&product=${product}`)
            .then(response => { 
                if (!response.ok) {
                    throw new Error("Failed to fetch data");
                }

                return response.json();
            })
            .then(data => {
                if (data.error === "No data found") {                    
                    window.location.href = '/notfound';
                } else {
                    resolve(data);
                }
            })
            .catch(error => {
                console.error("Error fetching data:", error);
                reject(error);
            });
    });
};


const GetMenu = () => {
    return new Promise((resolve, reject) => {
        fetch(`${API_SERVER}/getMenu`)
            .then(response => {
                if (!response.ok) {
                    throw new Error("Failed to fetch data");
                }
                return response.json();
            })
            .then(data => {
                
                if (data.error === "No data found") {
                    console.log(data.error);                    
                }
                resolve(data);
            })
            .catch(error => {
                reject(error);
            });
    });
}


const GetProdBySKU = async (sku) => {
    const response = await fetch(`${API_SERVER}/sku?sku=${sku}`);
    if (!response.ok) {
        throw new Error("Failed to fetch data");
    }
    const data = await response.json();
    return data;
};
  

const GetProdByName = (name) => {
    return new Promise((resolve, reject) => {
        fetch(`${API_SERVER}/name?name=${name}`)
            .then(response => {
                if (!response.ok) {
                    throw new Error("Failed to fetch data");
                }
                return response.json();
            })
            .then(data => {
                resolve(data);
            })
            .catch(error => {
                reject(error);
            });
    });
}

export { GetProdOrCat, GetProdBySKU, GetProdByName, GetItems, GetMenu };