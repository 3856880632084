import React, { useState, useEffect } from 'react';
import './Slideshow.css'; 

const Slideshow = ({images}) => {
  const [currentIndex, setCurrentIndex] = useState(0); 
  

  useEffect(() => {
    const interval = setInterval(() => {
      nextSlide();
    }, 8000); 
    return () => clearInterval(interval);
  }, [currentIndex]);

  const nextSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    );
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  const setSlide = (index) => {
    setCurrentIndex(index);
  };
  
  return (
    <div className="slideshow-container">
      {images.length > 0 &&
      images.map((image, index) => (
        <div
          key={index}
          className={`slide ${index === currentIndex ? 'active' : ''}`}
          style={{ display: index === currentIndex ? 'block' : 'none' }}
        >
          <a href={image.link} target="_blank" rel="noopener noreferrer">
            <img src={`/ReklamasFoto/${image.src}`} alt={image.alt} />
          </a>
        </div>
      ))}

      <div className="prev" onClick={prevSlide}>
        &#10094;
      </div>
      <div className="next" onClick={nextSlide}>
        &#10095;
      </div>

      <div className="indicators">
        {images.length > 0 &&
        images.map((_, index) => (
          <span
            key={index}
            className={`dot ${index === currentIndex ? 'active' : ''}`}
            onClick={() => setSlide(index)}
          ></span>
        ))}
      </div>
    </div>
  );
};

export default Slideshow;
