// Cart.js

import React, { useState, useEffect } from 'react';
import { getCart, updateQuantity } from '../../context/cartFunctions'; // Assuming you've placed cartLogic.js in the same directory
import './cart.css';
import { Link, useLocation } from 'react-router-dom';
import ProgressBar from 'react-bootstrap/ProgressBar';
import { RiDeleteBinLine } from "react-icons/ri";
import { onAuthStateChanged } from 'firebase/auth';
import useFirebase from "../../firebase/usefirebase";
import { Helmet } from 'react-helmet';
import { RiQuestionLine } from "react-icons/ri";
import {loadKlixScript} from '..//..//context/clikx';


const CART_KEY = 'cart';

const CartMain = () => {
  const [cartItems, setCartItems] = useState(false);
  const [dati, setDati] = useState(false);
  const [total, setTotal] = useState(0);
  const [weight, setWeight] = useState(false);
  const [maxParcelShopWeight, setMaxParcelShopWeight] = useState(0);
  const [authUser, setAuthUser] = useState(null);
  const [email, setEmail] = useState(null);
  const [freeDelivery, setFreeDelivery] = useState();
  const [smallOrderFee, setSmallOrderFee] = useState(0);
  const [smallOrderFeeLimit, setSmallOrderFeeLimit] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [authLoading, setAuthLoading] = useState(true);
  const [showKlix, setShowKlix] = useState(true);
  const { auth, user } = useFirebase();
  const [showReject, setShowReject] = useState(false);
  const [onlyCartridgesInCart, setOnlyCartridgesInCart] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const location = useLocation();
  
  const API_SERVER = process.env.REACT_APP_API_SERVER;

  let totalValue = 0;



  useEffect(() => {
    if (auth) {
      const unsubscribe = onAuthStateChanged(auth, (user) => {
        if (user) {
          setAuthUser(user);
          setEmail(user.email);
          setAuthLoading(false);
        } else {
          setAuthUser(null);
          setAuthLoading(false);
        }
      });
      return () => {
        unsubscribe();
      };
    } else {
      setAuthLoading(false); 
    }
    
  }, [API_SERVER, auth]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const data = queryParams.get('data');    
    if (queryParams.get('error') === 'paymentFailed') {
      setShowReject(true);
    }
    async function CheckPayment() {
      try {
        fetch(`${API_SERVER}/noSuccess`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ data: Number(data) }),
        });
      } catch (error) {
        console.error(error);
      }
    }
    if (data) {
      CheckPayment();
    }    
  }, [location]);

  useEffect(() => {
    if (authLoading) return; 
    const handleChange = () => {
      const saturs = getCart();
      setShowKlix(true);
      if (saturs.length > 0) {
        const skus = saturs.map(item => item.sku);
        const skuString = skus.join(',');
        fetch(`${API_SERVER}/sku?skus=${skuString}&email=${email}`)
          .then((response) => {
                    if (!response.ok) {
                        throw new Error("Failed to fetch user data");
                    }
                    return response.json();
                })
                .then((data) => {
                    setDati(data);
                    setCartItems(getCart());
                    fetch(`${API_SERVER}/freeDelivery`)
                    .then((response) => {
                      if (!response.ok) {
                          throw new Error("Failed to fetch user data");
                      }
                      return response.json();
                  })
                  .then((data) => {                    
                      setFreeDelivery(data.free);
                      setSmallOrderFee(parseFloat(data.smallOrderFee));
                      setSmallOrderFeeLimit(parseFloat(data.smallOrderFeeLimit));
                      setMaxParcelShopWeight(parseFloat(data.MaxSvarsPakomats));
                  })
                })
                .catch((error) => console.error(error));
      } else if (saturs.length === 0) {
        setCartItems(getCart());
        setIsLoading(false);        
      }
    };

    

    handleChange();
    window.addEventListener('storage', handleChange);
    
    
    return () => {
      window.removeEventListener('storage', handleChange);
    };
  }, [authUser, authLoading]);

  useEffect(() => {     
    const calculateTotal = async () => {
      let totalValue = 0;
      let totalWeight = 0;
      if (dati && cartItems) {
        cartItems.forEach((item) => {
          const product = dati.find((p) => p.SKU === item.sku);
          totalValue += item.quantity * product.PRICE;
          totalWeight += item.quantity * product.WEIGHT;

          if (Number(product.QTY) === 0 || product.QTY === null) {
            setShowKlix(false);
          }
        });

        setTotal(parseFloat(totalValue));
        if (totalValue < 79.99) {
          setShowKlix(false);
        }
        setWeight(parseFloat(totalWeight));
        const allContainCartridge = dati.every(row => row.MENU_1.includes('ārtridži'));

        if (allContainCartridge) {
          setOnlyCartridgesInCart(true);
        } else {
          setOnlyCartridgesInCart(false);
        }
        setIsLoading(false);
      }
    };
    calculateTotal();
    loadKlixScript();
  }, [dati, cartItems]);
  


  const handleQuantityChange = (sku, newQuantity) => {
    const updatedCart = cartItems.map((item) =>
      item.sku === sku ? { ...item, quantity: newQuantity } : item
    );
    
    updateQuantity(sku, newQuantity);
    setCartItems(updatedCart);
  };


const saveCart = (cart) => {
  localStorage.setItem(CART_KEY, JSON.stringify(cart));
  window.dispatchEvent(new Event('storage'));
};


const removeFromCart = (sku) => {
  const cart = getCart();
  const updatedCart = cart.filter((item) => item.sku !== sku);
  saveCart(updatedCart);
};

const handleIconClick = () => {  
  setShowNotification(true);
  setTimeout(() => {
    setShowNotification(false);
  }, 2000);
};
  
  return (
    <div className="cart-container-Main">
      <Helmet>
        <title>Jūsu grozs - Officeshop</title>
        <meta name="description" content="Pārskatiet savu grozu Officeshop.lv" />
        <meta name="robots" content="noindex, nofollow" />
        <meta property="og:title" content="Jūsu grozs - Officeshop" />
        <meta property="og:description" content="Pārskatiet savu grozu Officeshop.lv" />
        <meta property="og:url" content="https://www.officeshop.lv/cart" />
        <meta property="og:image" content="https://www.officeshop.lv/prodImages/logo.png" />
        <meta property="og:type" content="website" />
      </Helmet>
      {showReject ? (
                <>
                    <div className="Cover-container">
                        <div className="logs">
                            <h2>Kļūda Klix pasūtījuma apstrāde</h2>
                            <p>Veicot pasūtījumu Klix platformā radās kļūda, lūdzu mēģiniet vēlreiz vai izvēlieties citu apmaksas veidu!</p>                            
                            <button className='logIn' onClick={(e) => { e.preventDefault(); setShowReject(false) }} >Ok</button>
                        </div>
                    </div>
                </>
            ) : null}      
      {isLoading ? <div style={{display: "flex", flexDirection:"column", alignItems: "center", minHeight: "58vh"}}> <img src='/images/loading.gif' alt='loading' /> </div> : 
      (!cartItems || cartItems.length === 0) && !isLoading  ? 
            <div style={{display: "flex", flexDirection:"column", alignItems: "center", minHeight: "58vh"}}>
            <h4>Jūsu grozs pašreiz ir tukšs!</h4>
            <p>Ielieciet preces grozā un tās parādīsies šeit!</p>
            </div>
            : (
        <>
          <div className="cart-items-container">
          {weight < maxParcelShopWeight || onlyCartridgesInCart ? (
            total < freeDelivery && !onlyCartridgesInCart ? (
              <>
                <ProgressBar now={(total / freeDelivery) * 100} className="custom-progress-bar" />
                <p style={{ fontSize: '13px' }}>Līdz bezmaksas piegādei: €{(freeDelivery - total).toFixed(2)} </p>
              </>
            ) : (
              <div className='Free'>
              <img src='/images/freeDelivery.gif' alt='Bezmaksas piegāde | OfficeShop' className='freeDeliveryImageMain'/>
              </div>
            )
          ) : (
            <div className='Free'>
            <img src='/images/kurjeraPiegade.gif' alt='Kurjera piegāde | OfficeShop' className='freeDeliveryImageMain'/>
            </div>
          )}

            {cartItems.map((item) => {
              const product = dati.find((p) => p.SKU === item.sku);              
              return (
                <div className="cart-items" key={item.sku}>
                  <div className="cart-item">
                    <div className="product-image-container">
                    <img src={
                        product.MENU_2 === "Officeshop ražotie kārtridži"?  '/prodImages/Small/Officeshop Cartridges (Small)/cartridges (Small).jpg':
                        product.MENU_1 === "Kopējošās lentas (ribbon)" ? '/prodImages/Small/Ribbon (Small)/ribbon (Small).jpg':
                        product.PICTURE ? product.PICTURE.startsWith('http') ? product.PICTURE : `/prodImages/Small/${product.SKU} (Small)/${product.SKU} (Small).${product.PICTURE}`:
                        `/prodImages/Small/logo (Small)/logo (Small).png`} alt={product.NAME} className="image" />
                    </div>
                    <p className="name">
                      <Link to={
                        product.MENU_2 === 'N/A' ? `/categories/${product.SKU}` :
                          product.MENU_3 === 'N/A' ? `/categories/${encodeURIComponent(product.MENU_1).replace(/%20/g, '-')}/${encodeURIComponent(product.MENU_2).replace(/%20/g, '-')}/${product.SKU}` :
                            `/categories/${encodeURIComponent(product.MENU_1).replace(/%20/g, '-')}/${encodeURIComponent(product.MENU_2).replace(/%20/g, '-')}/${encodeURIComponent(product.MENU_3).replace(/%20/g, '-')}/${product.SKU}`
                      }>
                        {product.NAME}
                      </Link>
                    </p>
                    <p className="cena">€{product.PRICE}</p>
                    <input
                      className='quantity-input'
                      type="number"
                      value={item.quantity}
                      onChange={(e) =>
                        handleQuantityChange(item.sku, parseInt(e.target.value, 10))
                      }
                    min={product.MINORDER ? product.MINORDER : 1}
                  />
                  <p className="cena">€{(product.PRICE * item.quantity).toFixed(2)}</p>
                  <RiDeleteBinLine
                    style={{
                      color: "black",
                    }}
                    className="remove-button" 
                    onClick={() => removeFromCart(product.SKU)}
                  />
                </div>
              </div>
              );
            })}
            {showKlix && total >79.99 && (            
            <div className='mainCartWidget'>    
            <klix-pay-later amount={(total.toFixed(0))*100} brand_id="dd14917f-4fa5-47e7-a39e-a7a5764e712d" 
                language="lv" theme="light" view="product" category="SPORTS_GOODS" type={window.innerWidth < 768 ? "micro" : undefined}>
              </klix-pay-later>
            </div>
            )}
          </div>         
          
          <div className="total-container">
            {total < smallOrderFeeLimit ? (
                  <>
                    <div className='smallOrderNotification'>
                      <RiQuestionLine
                        title={`Pasūtījumiem līdz € ${smallOrderFeeLimit} tiek piemērota apkalpošanas maksa € ${smallOrderFee}`}
                        onClick={(e) => { e.preventDefault(); window.innerWidth < 768 && handleIconClick(); }}
                      />
                      Maza pasūtījuma apkalpošanas maksa:   € {smallOrderFee}
                    </div>
                    {showNotification && (
                      <div className="smallOrderNotificationMobile">
                        Pasūtījumiem līdz € {smallOrderFeeLimit} tiek piemērota apkalpošanas maksa € {smallOrderFee}
                      </div>
                    )}
                    <p>Kopā apmaksai:   € {(total + smallOrderFee).toFixed(2)}</p>
                  </>
            ) : (            
            <p>Kopā apmaksai:   € {total.toFixed(2)}</p>
            )}
            <button className="btn-small-red-center" onClick={(e) => {e.preventDefault(); window.location.href = !showKlix ? '/checkout' : '/checkout?data=full';}}>Pasūtīt</button>
          </div>
        </>
      )}
    </div>
  );
};

export default CartMain;
