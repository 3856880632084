
import React from "react";


import { FaRegHandshake } from "react-icons/fa";
import { LuBadgePercent } from "react-icons/lu";
import { TbTruckDelivery } from "react-icons/tb";
import './header.css';

export function FirstRow() {
    return (
        <>
            <div className="firstRowSectionWrapper">
                <TbTruckDelivery
                    className="firstRowIcon"
                />
                <div className='virsraksts' >
                    <div className="triger">Ātra piegāde</div>
                    <div className="hiddenDiv">
                        <p>Mēs saprotam cik svarīgi ir saņemt preci savlaicīgi, tāpēc nodrošinām ātru preces piegādi uz <strong>DPD un Omniva pakomātiem</strong> vai piegādi <strong>ar kurjeru uz Jūsu norādīto adresi</strong>.</p>
                        <p>Iepērcies un izvēlies sev piemērotāko piegādes veidu!</p>
                        <p>Droši, ātri un ērti!</p>
                    </div>
                </div>
            </div>
            <div className="firstRowSectionWrapper">
                <FaRegHandshake
                    className="firstRowIcon"
                />
                <div className='virsraksts' >
                    <div className="triger2">Uzticams sadarbības partneris</div>
                    <div className="hiddenDiv2">
                        <p>Mēs apkalpojam <strong>vairāk kā 100 Latvijas uzņēmumus</strong> un uzskatām par goda lietu darīt visu, lai kliens ir apmierināts.</p>
                        <p><strong>Palīdzēsim piemeklēt Jums atbilstošāko produktu, ieteiksim labāko risinājumu vai saremontēsim Jūsu biroja tehniku</strong></p>
                        <p>Sazinies ar mums, lai uzsāktu sadarbību!</p>
                    </div>
                </div>
            </div>
            <div className="firstRowSectionWrapper">
                <LuBadgePercent
                    className="firstRowIcon"
                />
                <div className='virsraksts' >
                    <div className="triger3">Vairumā cenas pievilcīgākas</div>
                    <div className="hiddenDiv3">
                        <p>Lielākiem pasūtījumiem juridiskām personām piemērojam <strong>vairuma atlaides</strong></p>
                        <p>Aicinām sazināties ar mums un <strong>mēs sagatavosim piedāvājumu tieši Jūsu uzņēmumam</strong> nepieciešamajiem produktiem un apjomiem <strong>par īpašām cenām</strong></p>
                        <p>Noslēdzot sadarbības līgumu, nodrošinām bezmaksas piegādi!</p>
                    </div>
                </div>
            </div>
        </>
    );
}

