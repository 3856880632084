
import { useEffect } from 'react';
import { Helmet } from 'react-helmet';

export default function Categories() {
    useEffect(() => {
        setTimeout(() => {
            window.location.href = '/categories';
        }, 2500);
    }, []);

    return (
        <>
            <Helmet>
                <title>Page Not Found - Officeshop</title>
                <meta name="description" content="Atvainojiet, šāda lapa neeksistē." />
                <meta name="robots" content="noindex, nofollow" />
                <meta property="og:title" content="Atvainojiet, šāda lapa neeksistē." />
                <meta property="og:description" content="Atvainojiet, šāda lapa neeksistē." />
                <meta property="og:url" content="https://www.officeshop.lv/404" />
                <meta property="og:type" content="website" />
            </Helmet>
            <div style={{ textAlign: 'center', padding: '50px', minHeight: "58vh" }}>
                <h1>Lapa netika atrasta</h1>
                <p>Atvainojiet, šāda lapa neeksistē. Jūs tiksiet novirzīts uz sākumu!</p>
            </div>
        </>
    );
}