import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import "./side.css";




const RightImage = () => {
    const [RightImage1, setRightImage1] = useState();
    const [link1, setLink1] = useState();
    const [RightImage2, setRightImage2] = useState();
    const [link2, setLink2] = useState();
    const [RightImage3, setRightImage3] = useState();
    const [link3, setLink3] = useState();
    const [apraksts1, setApraksts1] = useState();
    const [apraksts2, setApraksts2] = useState();
    const [apraksts3, setApraksts3] = useState();    
    const API_SERVER = process.env.REACT_APP_API_SERVER;


    useEffect(() => {
        fetch(`${API_SERVER}/adds?ReklamasVeids=Right`)
            .then((response) => {
                if (!response.ok) {
                    throw new Error("Failed to fetch Right adds");
                }
                return response.json();
            })
            .then((data) => {
                data.top.FailaNosaukums1 && setRightImage1(data.top.FailaNosaukums1);
                data.top.Faila1Links && setLink1(data.top.Faila1Links);
                data.top.Apraksts && setApraksts1(data.top.Apraksts);
                data.mid.FailaNosaukums1 && setRightImage2(data.mid.FailaNosaukums1);
                data.mid.Faila1Links && setLink2(data.mid.Faila1Links);
                data.mid.Apraksts && setApraksts2(data.mid.Apraksts);
                data.bot.FailaNosaukums1 && setRightImage3(data.bot.FailaNosaukums1);
                data.bot.Faila1Links && setLink3(data.bot.Faila1Links);              
                data.bot.Apraksts && setApraksts3(data.bot.Apraksts);
                
            })
            .catch((error) => console.error(error));
    }, []);
    
    return (
        <div className="left">
            {link1 ? (
                <Link to={`/${link1}`}>
                    <img src={`${process.env.PUBLIC_URL}/ReklamasFoto/${RightImage1}`} alt={`${apraksts1}`} />
                </Link>
            ) : RightImage1 ? (
                <img src={`${process.env.PUBLIC_URL}/ReklamasFoto/${RightImage1}`} alt={`${apraksts1}`} />
            ) : null
            }
            {link2 ? (
                <Link to={`/${link2}`}>
                    <img src={`${process.env.PUBLIC_URL}/ReklamasFoto/${RightImage2}`} alt={`${apraksts2}`} />
                </Link>
            ) : RightImage2 ? (
                <img src={`${process.env.PUBLIC_URL}/ReklamasFoto/${RightImage2}`} alt={`${apraksts2}`} />
            ) : null
            }
            {link3 ? (
                <Link to={`/${link3}`}>
                    <img src={`${process.env.PUBLIC_URL}/ReklamasFoto/${RightImage3}`} alt={`${apraksts3}`} />
                </Link>
            ) : RightImage3 ? (
                <img src={`${process.env.PUBLIC_URL}/ReklamasFoto/${RightImage3}`} alt={`${apraksts3}`} />
            ) : null
            }
        </div>
    )
}

export default RightImage;