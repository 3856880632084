import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import './Main.css';
import { GetItems } from "..//components/ProdHandleLogic.js";
import Mobile from '../components/Reklama/Sides/Mobile.js';
import Slideshow from '../components/Slideshow/slideshow';
import ProductSlider from '../components/Reklama/Carousel/Carousel.js';


const HomePage = () => {
  const [menuItems, setMenuItems] = useState([]);
  const [images, setImages] = useState([]);
  const [rightImages, setRightImages] = useState([]);
  const API_SERVER = process.env.REACT_APP_API_SERVER;
  const [isLoading, setIsLoading] = useState(true);
  const [showSuccess, setShowSuccess] = useState(false);
  const location = useLocation();

  const mainJsonLd = {
    "@context": "https://schema.org",
    "@type": "LocalBusiness",
    "name": "OfficeShop.lv",
    "url": "https://www.officeshop.lv",
    "contactPoint": {
      "@type": "ContactPoint",
      "contactType": "Customer Service",
      "telephone": "+37126828918",
      "email": "info@officeshop.lv"
    },
    "openingHoursSpecification": [
      {
        "@type": "OpeningHoursSpecification",
        "dayOfWeek": "Monday",
        "opens": "08:00",
        "closes": "17:00"
      },
      {
        "@type": "OpeningHoursSpecification",
        "dayOfWeek": "Tuesday",
        "opens": "08:00",
        "closes": "17:00"
      },
      {
        "@type": "OpeningHoursSpecification",
        "dayOfWeek": "Wednesday",
        "opens": "08:00",
        "closes": "17:00"
      },
      {
        "@type": "OpeningHoursSpecification",
        "dayOfWeek": "Thursday",
        "opens": "08:00",
        "closes": "17:00"
      },
      {
        "@type": "OpeningHoursSpecification",
        "dayOfWeek": "Friday",
        "opens": "08:00",
        "closes": "17:00"
      },
      {
        "@type": "OpeningHoursSpecification",
        "dayOfWeek": "Saturday",
        "opens": "Closed",
        "closes": "Closed"
      },
      {
        "@type": "OpeningHoursSpecification",
        "dayOfWeek": "Sunday",
        "opens": "Closed",
        "closes": "Closed"
      }
    ]
  };
  


  useEffect(() => {
    if (window.innerWidth > 768) {
    fetch(`${API_SERVER}/adds?ReklamasVeids=Karuselis`)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to fetch adds");
        }
        return response.json();
      })
      .then((data) => {
        const imageArray = data.karuselis.map((karuselis) => ({
          src: karuselis[0].FailaNosaukums1,
          link: karuselis[0].Faila1Links,
          alt: karuselis[0].Apraksts,
        })).filter(image => image.src !== "");

        setImages(imageArray); 
        const imageArray2 = data.main.map((image) => ({
          src: image.FailaNosaukums1,
          link: image.Faila1Links,
          alt: image.Apraksts,
        })).filter(image => image.src !== "");
        setRightImages(imageArray2);
        setIsLoading(false);
      })
      .catch((error) => console.error(error));
    } else {    
    GetItems(null, null, null, null, null)
      .then((data) => {
        setMenuItems(data.menu);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error(error);
      });
    }
    
  }, []);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const data = queryParams.get('data');
    async function SuccessData() {
      try{
        const response = await fetch(`${API_SERVER}/verifyPmt`, {
          method: 'PUT',
          headers: {
              'Content-Type': 'application/json'
          },
          body: JSON.stringify({ data: Number(data) }),        
          
      });
        if (!response.ok) {
          throw new Error("Failed to fetch user data");
        }
        if (response.status === 200) {
          setShowSuccess(true);
          localStorage.removeItem('cart');
          localStorage.setItem('cart', JSON.stringify([]));
          window.dispatchEvent(new Event('storage'));
        }
        } catch (error) {
          console.error(error);
        }
    }
    if (data) {
      SuccessData();      
    } 
  }, [location]);
 
  return (
    <>
      <Helmet>
        <title>OfficeShop.lv - Printeru Kārtridži, Biroja un elektro preces</title>
        <meta name="description" content="Plašā sortimentā biroja, skolas un elektro preces. Printeru kārtridži. Draudzīgas cenas, ātra piegāde un uzticams serviss! Iepērcies!" />
        <meta name="keywords" content="Printeru kārtridži, biroja preces, printeru remonts, skolas preces, skolas komplekti, mobilais telefons, kārtridžu uzpilde, datori, video karte, kārtridžu utiizācija" />
        <meta name="robots" content="index, follow" />
        <meta property="og:title" content="Officeshop.lv - Printeru Kārtridži. Biroja, skolas un elektro preces" />
        <meta property="og:description" content="Piedāvājam plašu printeru kārtridžu un biroja preču izvēli. Ātra piegāde un uzticams serviss." />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.officeshop.lv/" />
        <meta property="og:image" content="https://www.officeshop.lv/prodImages/logo.png" />
        <meta property="og:image:alt" content="OfficeShop.lv Logo" />
        <link rel="canonical" href="https://www.officeshop.lv/" />
        <script type="application/ld+json">{JSON.stringify(mainJsonLd)}</script>
      </Helmet>
      {window.innerWidth > 768 && 
      <div className='landingContainer'>
        {showSuccess ? (
                <>
                    <div className="Cover-container">
                        <div className="logs">
                            <h2>Jūsu pasūtījums ir pabeigts!</h2>
                            <p>Esam veiksmīgi saņēmuši apmaksu par Jūsu pasūtījumu ar Klix pay later starpniecību, sākam pasūtījuma izpildi!</p>
                            <p>Uz Jūsu norādīto e-pastu drīzumā nosūtīsim apstiprinājumu.</p>
                            <button className='logIn' onClick={(e) => { e.preventDefault(); setShowSuccess(false) }} >Ok</button>
                        </div>
                    </div>
                </>
            ) : null}
        <div className='mainRow'>
          <div className='mainColumnLeft'>
            {isLoading ?
             <img src='/images/loading.gif' alt='loading' style={{marginLeft:"35%"}}/>
             :
            <Slideshow images={images} />}
          </div>
          <div className='mainColumnRight'>
            {rightImages.length > 0 &&
              rightImages.map((image, index) => (
                <div className='smallMainContainer' index={index}>
                  <img src={`/ReklamasFoto/${image.src}`} alt={image.alt} onClick={() => window.location.href = image.link}/>
                </div>
              ))}
          </div>
        </div>
        <div className='main2ndRow'>
          <ProductSlider />
        </div>
      </div>
      }      
      
      
      {window.innerWidth <= 768 && (        
        <>
          <Mobile />
          <div className="landingContainerMobile">
            {isLoading || menuItems.length === 0 ? (
              <img src='/images/loading.gif' alt='loading' className='loading' />
            ) : (
            menuItems.map((item, index) => (
              <div
                key={index}
                className="item"
                onClick={(e) => {
                  e.preventDefault();
                  window.location.href = `/categories/${item.MENU_1.replace(/\s/g, '-')}`;
                }}
              >
                <img
                  src={`/catImages/${item.MENU_1}.jpg`}
                  alt={item.MENU_1}
                  className="SmallMainCatImage"
                />
                {item.MENU_1}
              </div>
            ))
            )}
          </div>
          <ProductSlider />
        </>
      )}
    </>
  );
};

export default HomePage;
