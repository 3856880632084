import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { GetMenu } from "../ProdHandleLogic.js";

import './Drop.css'
import { IoReorderThreeOutline } from "react-icons/io5";
import { hr } from "date-fns/locale";

export function AllCategoriesFull() {
    const [menuItems, setMenuItems] = useState([]);
    const [selectedMenu1, setSelectedMenu1] = useState(null);
    const [isActive, setIsActive] = useState(false);    
    const location = useLocation();

    useEffect(() => {
        GetMenu()
            .then((data) => {
                const result = data.reduce((acc, current) => {
                    const { MENU_1, MENU_2, MENU_3 } = current;

                    if (!acc[MENU_1]) {
                        acc[MENU_1] = {};
                    }

                    if (!acc[MENU_1][MENU_2]) {
                        acc[MENU_1][MENU_2] = new Set();
                    }

                    acc[MENU_1][MENU_2].add(MENU_3);

                    return acc;
                }, {});

                for (const menu1 in result) {
                    for (const menu2 in result[menu1]) {
                        result[menu1][menu2] = Array.from(result[menu1][menu2]);
                    }
                }

                setMenuItems(result);
            })
            .catch((error) => {
                console.error(error);
            });            
    }, []);

    const handleClick = (menu1) => {
        setIsActive(!isActive);
    };
    const handleMouseEnter = (menu1) => {
        setSelectedMenu1(menu1);
    };

    const handleMouseLeave = () => {
            setSelectedMenu1(null);
    };

    
    return (
        <div className="main-container">
            <div className="virsraksts"
            onClick={(e) => {
                e.preventDefault();
                if (location.pathname === '/') {
                    window.location.href = '/categories';
                }
                location.pathname !== '/' && handleClick();
            }}     >
                <IoReorderThreeOutline
                    className="thirdRowIcon"
                />                
                <div            
                >Preču kategorijas</div>
            </div>
            {(location.pathname === '/' || (location.pathname !== '/' && isActive)|| location.pathname === '/thermalTransfer') && menuItems.length !== 0 && (
            <div className="FullContent">
                {Object.keys(menuItems).sort((a, b) => a.localeCompare(b)).map((menu1, index) => (
                    <>
                        <div key={menu1} className="categryRow" onMouseEnter={() => handleMouseEnter(menu1)}
                            onMouseLeave={handleMouseLeave}>
                            <img src={`/catImages/${menu1}.jpg`} alt={menu1} className="smallAllCategoriesImage" />
                            <div onClick={(e) => {
                                e.preventDefault();
                                if (menu1 === "Printeru kārtridži") {
                                    window.location.href = `/cartridges`;
                                } else if (menu1 === "Kopējošās lentas (ribbon)") {
                                    window.location.href = `/thermalTransfer`;
                                } else  {
                                    window.location.href = `/categories/${menu1.replace(/\s/g, '-')}`;
                                }
                            }}>
                                {menu1}
                            </div>
                            {selectedMenu1 === menu1 && (                            
                                <div className="SubkategorijuKonteiners"
                                style={
                                    index > 16 
                                    ? { bottom: `5px` } 
                                    : { top: `${index * 5 + 155}px` }
                                }>
                                    {
                                    Object.keys(menuItems[menu1]).sort((a, b) => a.localeCompare(b)).map(menu2 => (
                                        <div key={menu2} className="submenu">
                                            <div className="mainCategoryLink" onClick={(e) => {
                                                e.preventDefault();
                                                window.location.href = `/categories/${menu1.replace(/\s/g, '-')}/${menu2.replace(/\s/g, '-')}`;
                                            }}>
                                                
                                                    <>
                                                        <img src={`/catImages/${menu2}.jpg`} alt={menu2} className="smallAllCategoriesImage" />
                                                        {menu2}
                                                    </>
                                                
                                            </div>
                                            {menuItems[menu1][menu2].sort((a, b) => a.localeCompare(b)).map(menu3 => (
                                                menu3 !== 'N/A' &&
                                                <div className="subsubmenu" key={menu3} onClick={(e) => {
                                                    e.preventDefault();
                                                    window.location.href = `/categories/${menu1.replace(/\s/g, '-')}/${menu2.replace(/\s/g, '-')}/${menu3.replace(/\s/g, '-')}`;
                                                }}>
                                                    {menu3}
                                                </div>
                                            ))}

                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                    </>
                ))}
            </div>
            )}
        </div>
    );
}
