

// cartLogic.js

const CART_KEY = 'cart';




const getCart = () => {
  const cartData = localStorage.getItem(CART_KEY);
  return cartData ? JSON.parse(cartData) : [];
};


const saveCart = (cart) => {
  localStorage.setItem(CART_KEY, JSON.stringify(cart));
  window.dispatchEvent(new Event('storage'));

};


const addToCart = (sku, quantity) => {
 
  const cart = getCart();

  
  const existingItem = cart.find((item) => item.sku === sku);

  if (existingItem) {
    
    existingItem.quantity += parseInt(quantity, 10);
  } else {
    
    cart.push({sku, quantity});
  }

  
  saveCart(cart);
  
};


const removeFromCart = (sku) => {
 
  const cart = getCart();

  
  const updatedCart = cart.filter((item) => item.sku !== sku);

 
  saveCart(updatedCart);
};


const updateQuantity = (sku, quantity) => {
  
  const cart = getCart();

  
  const updatedCart = cart.map((item) =>
    item.sku === sku ? { ...item, quantity } : item
  );

  
  saveCart(updatedCart);
};

export { getCart, addToCart, removeFromCart, updateQuantity };
