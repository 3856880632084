const loadKlixScript = () => {    
    const scriptEsm = document.createElement('script');
    scriptEsm.type = 'module';
    scriptEsm.src = 'https://klix.blob.core.windows.net/public/pay-later-widget/build/klix-pay-later-widget.esm.js';
    document.head.appendChild(scriptEsm);
  
    
    const scriptNoModule = document.createElement('script');
    scriptNoModule.setAttribute('nomodule', ''); 
    scriptNoModule.src = 'https://klix.blob.core.windows.net/public/pay-later-widget/build/klix-pay-later-widget.js';
    document.head.appendChild(scriptNoModule);
  };

export { loadKlixScript };