import React from "react";
import { RiFilePaper2Line } from "react-icons/ri";
import './Drop.css'


export function Papirs() {
    return (
        <div className="main-container">
            <div className="virsraksts" onClick={() => {window.location.href = "/categories/Biroja-preces/Papīrs-un-papīra-preces/Biroja-papīrs/"; }}>
                <RiFilePaper2Line
                    className="thirdRowIcon"
                />
                <div> Biroja papīrs</div>
                
            </div>
            
        </div>
    );
}
