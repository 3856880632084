import React from "react";
import './Drop.css'
import { PiBackpack } from "react-icons/pi";


export function SkolasPreces() {
    return (
        <div className="main-container">
            <div className="virsraksts" onClick={() => {window.location.href = "/categories/Skolas-preces/"; }}>
                <PiBackpack
                    className="thirdRowIcon"
                />
                <div> Skolas preces</div>
            </div>
        </div>
    );
}
