

import React, { useEffect } from 'react';
import './notifications.css';

const SuccessNotification = ({ message, onClose }) => {
    useEffect(() => {
        const timer = setTimeout(() => {
            onClose();
        }, 1000);
        return () => clearTimeout(timer);
    }, [onClose]);
    
    
    return (
        <div className="successNotification">
            <p className="notificationMessage">{message}</p>
        </div>
    );
};

export default SuccessNotification;