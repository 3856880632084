import { Container } from 'react-bootstrap';
import UserHangler from '..//Users/UserHandle';
import InputForm from '..//Users/contactForm';
import Search from '../Search/search';
import './header.css';
import { useLocation } from 'react-router-dom';
import { HiOutlineShoppingCart } from "react-icons/hi";
import { MdFavoriteBorder } from "react-icons/md";
import { AllCategoriesDrop } from '../Dropdowns/AllCategoriesDrop'
import { AllCategoriesFull } from '../Dropdowns/AllCategoriesFull';
import { FirstRow } from './firstRow';
import { Papirs } from '../Dropdowns/Papirs';
import { Kartridzi } from '../Dropdowns/Kartridzi';
import { Lentas } from '../Dropdowns/Lentas';
import { SkolasPreces } from '../Dropdowns/SkolasPreces';
import { Utilizacija } from '../Dropdowns/Utilizacija';
import CartridgeSearch from '..//Search/CartridgeSearch';
import { getCart } from '..//..//context/cartFunctions';
import Cart from '../../Pages/Cart/cart';
import { useEffect, useState } from 'react';






function Header() {

  const [cartItemsCount, setCartItemsCount] = useState(0);
  const [showCart, setShowCart] = useState(false);  
  const location = useLocation();


  useEffect(() => {
    const handleChange = async () => {
      const cart = getCart();
      let count = 0;
      if (cart.length > 0) {
      cart.forEach(item => {
        count += item.quantity;
      });
      }
      setCartItemsCount(count);
    };
    handleChange();
    window.addEventListener('storage', handleChange);
    return () => {
      window.removeEventListener('storage', handleChange);
    };
  }, []);
  
  
  return (
    <>
      <Container fluid className='firstRowHideOnMobile'>
        <FirstRow />
      </Container>
      <Container fluid className='baltais-menu-mobile'>
        <div className='mekletajs-mobile'>
          <Search />
        </div>
      </Container>
      <Container fluid style={{ backgroundColor: "#f70d1a" }} className='konteiners'>
        <div className='logo' onClick={() => window.location.href = '/'} >
          <img src={require('./logo.png')} alt="Logo" />
        </div>
        <div className='main-line-wrapper'>
          <div className='mekletajs-fullscreen'>            
              <Search />           
          </div>
          <div className='iconu-konteiners'>
            <UserHangler />
            <div className="main-line-item">
              <MdFavoriteBorder
                className='contact-icon'
                onClick={() => window.location.href = '/favorites'}
              />
            </div>
            <InputForm />
            <div className='main-line-item' >
              <HiOutlineShoppingCart
                className='contact-icon'
                onClick={() => window.location.href = '/cart'}
                onMouseEnter={() => setShowCart(true)}   
                  
              />
              {cartItemsCount > 0 && (
                <span
                  style={{
                    color: "white",
                    fontSize: "12px",
                    fontWeight: "bold",
                    position: "absolute",
                    zIndex: "1",
                    top: "-10px",
                    right: "5%",
                    backgroundColor: "#8BCB15",
                    borderRadius: "50%",
                    padding: "2px",
                    cursor: "pointer",
                    height: "18px",
                    width: "18px",
                    textAlign: "center",
                    lineHeight: "17px",
                  }}
                >
                  {cartItemsCount}
                </span>
              )}
              {showCart && cartItemsCount > 0 && (location.pathname==='/' || location.pathname==='/cartridges' || location.pathname==='/cartridgeSearchResults') && window.innerWidth > 768 && (
                <div className='cart-popup'                
                onMouseLeave={() => setShowCart(false)}>
                <Cart />
                </div>
                )}
            </div>
          </div>
        </div>
      </Container>      
        <Container fluid className='baltais-menu'>
          <AllCategoriesFull />
          <Papirs />
          <SkolasPreces />
          <Lentas />          
          <Kartridzi />
          {location.pathname !=='/cartridges' && <CartridgeSearch />}
          <Utilizacija />
        </Container>    
      
      <Container fluid className='zem-sarkana-mobile'>
          <AllCategoriesDrop />
          {location.pathname !=='/cartridges' && <CartridgeSearch />}
          {location.pathname ==='/cartridges' && <Utilizacija />}
      </Container>
    </>
  );
}

export default Header;