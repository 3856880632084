import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { onAuthStateChanged } from "firebase/auth";
import useFirebase from "../../firebase/usefirebase";
import { useEffect, useState } from "react";
import { Helmet } from 'react-helmet';
import './panel.css';
import { set } from 'date-fns';

const API_SERVER = process.env.REACT_APP_API_SERVER;


function Panel() {
    const [isAdmin, setIsAdmin] = useState(false);
    const [reklama, setReklama] = useState([{}]);
    const [files, setFiles] = useState([]);
    const [prices, setPrices] = useState([]);
    const [userSearch, setUserSearch] = useState([]);
    const [fixedSearch, setFixedSearch] = useState([]);
    const [newFixedPrice, setNewFixedPrice] = useState(""); 
    const [fixedPriceProducts, setFixedPriceProducts] = useState([]);
    const [SearchText, setSearchText] = useState('');
    const [SearchText2, setSearchText2] = useState('');
    const [SearchText3, setSearchText3] = useState('');
    const [SearchText4, setSearchText4] = useState('');
    const [SearchKods, setSearchKods] = useState(null);
    const [SearchName, setSearchName] = useState(null);
    const [defaultTab, setDefaultTab] = useState('Pasūtījumi');
    const [allowFixPriceInput, setAllowFixPriceInput] = useState(false);
    const [DataInProgress, setDataInProgress] = useState();
    const [orders, setOrders] = useState([]);
    const [orderListIsActive, setOrderListIsActive] = useState(null);
    const [showOrderIsActive, setShowOrderIsActive] = useState(null);
    const [newOrderStatus, setNewOrderStatus] = useState();
    const [TrackingNo, setTrackingNo] = useState(null);
    const [Carrier, setCarrier] = useState(null);
    const [AskReview, setAskReview] = useState(null);
    const [specialOffers, setSpecialOffers] = useState([]);
    const [showSpecialOffer, setShowSpecialOffer] = useState([]);
    const [companyList, setCompanyList] = useState([]);
    const [activeCompany, setActiveCompany] = useState(null);
    const [activeSku, setActiveSku] = useState(null);
    const [newOfferLine, setNewOfferLine] = useState(false);
    const [settings, setSettings] = useState([]);
    const { auth, user } = useFirebase();





    useEffect(() => {
        const fetchData = async () => {
            try {
                await new Promise((resolve) => {
                    if (auth){
                        const unsubscribe = onAuthStateChanged(auth, (user) => {
                            if (user) {
                                resolve(user);
                            } else {
                                resolve(null);
                            }
                            unsubscribe();
                        });
                    }
                });
                const user = auth.currentUser;
                if (user || DataInProgress === 'success') {
                    const verifyResponse = await fetch(`${API_SERVER}/verify?Email=${user.email}`);
                    if (!verifyResponse.ok) {
                        throw new Error("Failed to fetch user data");
                    }
                    const data = await verifyResponse.json();
                    setReklama(data.reklama.sort((a, b) => a.ReklamasVeids.localeCompare(b.ReklamasVeids)));
                    setPrices(data.pricesData);
                    setIsAdmin(data.verifyData);
                    setOrders(data.orders);
                    setSpecialOffers(data.specialOffers);
                    setCompanyList(data.companyList);
                    setSettings(data.settings);
                    setFiles(data.files);
                    setFixedPriceProducts(data.fixedPrices);
                }
            } catch (error) {
                console.error(error);
            }
        };
        fetchData();
    }, [auth, DataInProgress]);

    
    const handleInputChange = (index, fieldName, value) => {
        const updatedReklama = [...reklama];
        updatedReklama[index][fieldName] = value;
        setReklama(updatedReklama);
    };

    const handleReklamaChanged = () => {
        reklama.forEach((item) => {
            fetch(`${API_SERVER}/updateAdds`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(item),
            })
                .then((response) => {
                    if (!response.ok) {
                        throw new Error('Failed to update reklama data');
                    }
                })
                .catch((error) => {
                    console.error(error);
                });
        });
    };

    const handleFileUpload = async (file) => {
        const formData = new FormData();
        formData.append('file', file);

        try {
            const response = await fetch(`${API_SERVER}/upload`, {
                method: 'POST',
                body: formData
            });
            if (response.ok) {
                const fileName = await response.text();
                console.log('File uploaded:', fileName);
                
            } else {
                console.error('Failed to upload file');
            }
        } catch (error) {
            console.error('Error uploading file:', error);
        }
    };
    const handleFileInputChange = (event) => {
        const file = event.target.files[0];
        const maxSizeInKB = 1200;
        const maxSizeInBytes = maxSizeInKB * 1024;
        if (file.size > maxSizeInBytes) {
            alert("Faila izmērs pārsniedz 600KB. Lūdzu izvēlieties mazāku failu");
        } else {
        handleFileUpload(file);
        }
    };

    useEffect(() => {
        if (SearchText === "" || SearchText2 === "" || SearchText3 === "" || SearchText4 === "") {
            setUserSearch([])
        }
        if (SearchText !== "" || SearchText2 !== "" || SearchText3 !== "" || SearchText4 !== "") {
            const filteredData = prices.filter((item) => {                
                const menu1Text = item.MENU_1.toString();
                const menu2Text = item.MENU_2.toString();
                const menu3Text = item.MENU_3.toString();
                const locationText = item.Loc.toString();
                return menu1Text.toLowerCase().includes(SearchText.toLowerCase()) && menu2Text.toLowerCase().includes(SearchText2.toLowerCase()) && menu3Text.toLowerCase().includes(SearchText3.toLowerCase()) && locationText.toLowerCase().includes(SearchText4.toLowerCase());
            });
            setUserSearch(filteredData);
        }
    }, [SearchText, SearchText2, SearchText3, SearchText4]);

    const handleUzcChange = (fieldName, fieldName2, Loc, value) => {
        const updatedPrices = [...userSearch];
        const index = updatedPrices.findIndex((item) => item.MENU_2 === fieldName && item.MENU_3 === fieldName2 && item.Loc === Loc);
        updatedPrices[index].UZC = value;
        setUserSearch(updatedPrices);
    };

    const handleMinUzcChange = (fieldName, fieldName2, Loc, value) => {
        const updatedPrices = [...userSearch];
        const index = updatedPrices.findIndex((item) => item.MENU_2 === fieldName && item.MENU_3 === fieldName2 && item.Loc === Loc);
        updatedPrices[index].MINUZC = value;
        setUserSearch(updatedPrices);
    };

    const handleUzcSubmit = () => {
        setDataInProgress('progressing');
        Promise.all(userSearch.map((item) => {
            return fetch(`${API_SERVER}/updateData`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(item),
            })
                .then((response) => {
                    if (!response.ok) {
                        throw new Error('Failed to update reklama data');
                    }
                    return response.text(); 
                });
        }))
            .then(() => {
                setDataInProgress('success');
            })
            .catch((error) => {
                console.error(error);
                setDataInProgress('error');
            });
    };


    const showOrder = (invoice) => {
        setShowOrderIsActive(showOrderIsActive === invoice ? null : invoice);
    };

    async function handleOrderSubmit(invoiceNumber, email, tracking, courier, AskReview) {
        let InvoiceResponse;
    
        // First fetch (Invoice creation)
        if (newOrderStatus === 'completed') {
            try {
                InvoiceResponse = await fetch(`${API_SERVER}/createInvoice?Invoice=${invoiceNumber}`);
                if (!InvoiceResponse.ok) {
                    throw new Error('Failed to create invoice');
                }
            } catch (error) {
                console.error(error);
                return; // Stop execution if the invoice creation fails
            }
        }
    
        // Second fetch (Order update)
        try {
            const updateResponse = await fetch(`${API_SERVER}/updateOrder`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    Invoice: invoiceNumber,
                    orderStatus: newOrderStatus,
                    TrackingNo: tracking,
                    Carrier: courier,
                    Email: email,
                    salidziniLv: AskReview !== null ? AskReview : false,
                })
            });
    
            if (!updateResponse.ok) {
                throw new Error('Failed to update order data');
            }
    
            setDefaultTab('Pasūtījumi');
            window.location.reload();
        } catch (error) {
            console.error(error);
        }
    }
    

    const showOrderList = (orderStatus) => {
        setOrderListIsActive(orderListIsActive === orderStatus ? null : orderStatus);
        //setNewOrderStatus();
    };

    function createInvoiceTable(orderStatus, optionName1, optionValue1, optionName2, optionValue2, optionName3, optionValue3) {
        const newOrder = orders.filter((order) => order.Status === orderStatus);
        const uniqueInvoiceCount = [...new Set(newOrder.map(order => order.Invoice))].length;
        
        let text1, text2;
        switch (orderStatus) {
            case "new":
            text1 = " jauns pasūtījums";
            text2 = " jauni pasūtījumi";
            break;
            case "paymentOK":
            text1 = " apmaksāts un nenosūtīts pasūtījums";
            text2 = " apmaksāti un nenosūtīti pasūtījumi";
            break;
            case "rejected":
            text1 = " noraidīts pasūtījums";
            text2 = " noraidīti pasūtījumi";
            break;
            default:
            text1 = " pabeigts pasūtījums";
            text2 = " pabeigti pasūtījumi";
        }
        const ordersByInvoice = {};
        newOrder.forEach((order) => {
            if (!ordersByInvoice[order.Invoice]) {
                ordersByInvoice[order.Invoice] = [];
            }
            ordersByInvoice[order.Invoice].push(order);
        });
    
        return (
            <>
            <div className='ordermain' onClick={() => showOrderList(orderStatus)}>Jums ir {uniqueInvoiceCount} {uniqueInvoiceCount === 1 ? text1 : text2}</div>
            {orderListIsActive === orderStatus ? 
            Object.keys(ordersByInvoice).map((invoiceNumber) => {
            const orders = ordersByInvoice[invoiceNumber];
                return (
                    <table key={invoiceNumber} style={{ width: "80%", borderCollapse: "collapse" }}>
                        <thead>
                            <tr>
                                <td
                                    onClick={() => showOrder(invoiceNumber)}
                                    style={{ padding: '5px 10px', border: '1px solid #ccc', whiteSpace: 'nowrap', width: '5%' }}
                                >
                                    Rēķina Nr.
                                </td>
                                <td
                                    onClick={() => showOrder(invoiceNumber)}
                                    style={{ padding: '5px 10px', border: '1px solid #ccc', whiteSpace: 'nowrap', width: '5%' }}
                                >
                                    {invoiceNumber}
                                </td>
                                <td
                                    onClick={() => showOrder(invoiceNumber)}
                                    style={{ padding: '5px 10px', border: '1px solid #ccc', whiteSpace: 'nowrap', width: '8%' }}
                                >
                                    {orders[0].Name && orders[0].Surname
                                        ? `${orders[0].Name} ${orders[0].Surname}`
                                        : orders[0].CompanyName}
                                </td>
                                <td
                                    onClick={() => showOrder(invoiceNumber)}
                                    style={{ padding: '5px 10px', border: '1px solid #ccc', whiteSpace: 'nowrap', width: '8%' }}
                                >
                                    Kopsumma
                                </td>
                                <td
                                    onClick={() => showOrder(invoiceNumber)}
                                    style={{ padding: '5px 10px', border: '1px solid #ccc', whiteSpace: 'nowrap', width: '5%' }}
                                >
                                    € {orders.reduce((acc, order) => acc + order.QTY * order.SalesPrice, 0).toFixed(2)}
                                </td>
                                <td
                                    onClick={() => showOrder(invoiceNumber)}
                                    style={{ padding: '5px 10px', border: '1px solid #ccc', whiteSpace: 'nowrap', width: '5%' }}
                                >
                                    Peļņa:
                                </td>
                                <td
                                    onClick={() => showOrder(invoiceNumber)}
                                    style={{ padding: '5px 10px', border: '1px solid #ccc', whiteSpace: 'nowrap', width: '5%' }}
                                >
                                    € {orders
                                        .reduce(
                                            (acc, order) =>
                                                acc + order.QTY * ((order.SalesPrice / 1.21).toFixed(2) - order.PurchasePrice),
                                            0
                                        )
                                        .toFixed(2)}
                                </td>
                                <td
                                    style={{ padding: '5px 10px', border: '1px solid #ccc', whiteSpace: 'nowrap', width: '14%' }}
                                >
                                    {orders[0].Carrier === 'door-to-door' ? 'Piegādes adrese: ' : `Pakomāts (${orders[0].Carrier})`}
                                </td>
                                <td
                                    style={{ padding: '5px 10px', border: '1px solid #ccc', width: "45%" }}
                                >
                                    {orders[0].Carrier === 'door-to-door'
                                        ? `${orders[0].Street}, ${orders[0].City}, ${orders[0].PostCode}.`
                                        : orders[0].Pakomats}
                                    Tel. {orders[0].Phone} E-pasts: {orders[0].Email}
                                </td>
                            </tr>
                        </thead>
                        <tbody>
                            {showOrderIsActive === invoiceNumber &&
                                <>
                                    <tr>
                                        {optionValue1 !== "" || optionValue2 !== "" || optionValue3 !== "" ?
                                            <>
                                                <td>Mainīt statusu:</td>
                                                <td>
                                                    <select onChange={(e) => setNewOrderStatus(e.target.value)}>
                                                        <option></option>
                                                        {optionName1 !== "" && optionValue1 !== "" ? <option value={optionName1}>{optionValue1}</option> : null}
                                                        {optionName2 !== "" && optionValue2 !== "" ? <option value={optionName2}>{optionValue2}</option> : null}
                                                        {optionName3 !== "" && optionValue3 !== "" ? <option value={optionName3}>{optionValue3}</option> : null}
                                                    </select>
                                                </td>

                                                {newOrderStatus === 'completed' && showOrderIsActive === invoiceNumber &&
                                                    <>

                                                        <td>
                                                            <input onChange={(e) => setTrackingNo(e.target.value)}  type="text" placeholder={orders[0].TrackingNo}></input>
                                                        </td>
                                                        <td>
                                                            Kurjers:
                                                            <select onChange={(e) => setCarrier(e.target.value)}  >
                                                                <option>{orders[0].Carrier === "door-to-door" ? "Kurjera piegāde" :orders[0].Carrier }</option>
                                                                <option value="DPD">DPD</option>
                                                                <option value="Omniva">Omniva</option>
                                                                <option value="Venipak">Venipak</option>
                                                            </select>
                                                        </td>
                                                        <td>
                                                            Iekļaut e-pastā atsauksmes saņemšanu:
                                                            <select onChange={(e) => setAskReview(e.target.value)}>
                                                                <option>Nē</option>
                                                                <option value="true">Jā</option>
                                                            </select>
                                                        </td>

                                                    </>
                                                }

                                                <td>
                                                    <button onClick={() => { handleOrderSubmit(invoiceNumber, orders[0].Email, TrackingNo !== null ? TrackingNo : orders[0].TrackingNo, Carrier !== null ? Carrier : orders[0].Carrier, AskReview) }}>Saglabāt izmaiņas</button>
                                                </td>
                                            </>
                                            : null}
                                    </tr>
                                    <tr>
                                        <td>SKU</td>
                                        <td>Skaits</td>
                                        <td>Iepir. Cena</td>
                                        <td>Nosaukums</td>
                                        <td>Pārdoš. Cena</td>
                                        <td>Summa</td>
                                        <td>Tracking No.</td>
                                        <td>Kurjers</td>
                                        <td> Epasts nosūtīts</td>
                                    </tr>
                                    {orders.map((order) => (
                                        <tr key={order.SKU}>
                                            <td>{order.SKU}</td>
                                            <td>{order.QTY}</td>
                                            <td>€ {order.PurchasePrice}</td>
                                            <td>{order.Description}</td>
                                            <td>€ {order.SalesPrice}</td>
                                            <td>€ {(order.QTY * order.SalesPrice).toFixed(2)}</td>
                                            <td>{order.TrackingNo}</td>
                                            <td>{order.Carrier === 'door-to-door' ? 'Kurjera piegāde' : order.Carrier}</td>
                                            <td>{order.Status === order.EmailSent ? "Jā" : "Nē"}</td>
                                        </tr>
                                    ))}
                                </>
                            }
                        </tbody>
                    </table>
                );
                    })
            : null}
            </>
        )
    }

    useEffect(() => {
        setShowSpecialOffer([]);
        setNewOfferLine(false);
        const itemlist = specialOffers.filter(item => item.company === activeCompany);
        if (itemlist.length >= 1) {
            setShowSpecialOffer(itemlist);
        }
    }, [activeCompany, specialOffers]);

    const searchSKU = (sku) => {
        fetch(`${API_SERVER}/pecSKU?SKU=${sku}`)
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Failed to fetch data');
                }
                return response.json();
            })
            .then((data) => {
                if (data.length === 0) {
                    setActiveSku(null);
                } else {
                setActiveSku(data);
                }
            })
            .catch((error) => {
                console.error(error);
            });
    };

    
    useEffect(() => {
        if (SearchKods !== null || SearchName !== null) {
            fetch(`${API_SERVER}/fixed?SKU=${SearchKods}&NAME=${SearchName}`)
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Failed to fetch data');
                }
                return response.json();
            })
            .then((data) => {
                if (data.length === 0) {
                    setFixedSearch([]);
                } else {
                setFixedSearch(data);
                }
            })
            .catch((error) => {
                console.error(error);
            });
        }
    }, [SearchKods, SearchName]);

    const addNewSpecialOffer = (sku, price, company) => {
        fetch(`${API_SERVER}/addNewSpecialOffer`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                SKU: sku,
                Price: price,
                Company: company,
            }),
        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Failed to add new special offer');
                }
                return response.text(); 
            })
            .then(() => {
                window.location.reload();
                setDefaultTab('Īpašie piedāvājumi');
            })
            .catch((error) => {
                console.error(error);
            });
    }

    const deleteSpecialOffer = (sku, company) => {
        fetch(`${API_SERVER}/deleteSpecialOffer`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                SKU: sku,
                CompanyName: company,
            }),
        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Failed to delete special offer');
                }
                return response.text(); 
            })
            .then(() => {
                window.location.reload();
                setDefaultTab('Īpašie piedāvājumi');
            })
            .catch((error) => {
                console.error(error);
            });
        
    }

    const adjustSettings = (setting, value, cena) => {
        fetch(`${API_SERVER}/updateSettings`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                Setting: setting,
                Value: value,
                Cena: cena,
            }),
        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Failed to adjust settings');
                }
                return response.text(); 
            })
            .then(() => {
                window.location.reload();
                setDefaultTab('Uzstādījumi');
            })
            .catch((error) => {
                console.error(error);
            });
    }

    const handleFixPriceChange = () => {
        setAllowFixPriceInput(!allowFixPriceInput);
    }


    const handleFixPriceSubmit = (sku, price) => {
        setDataInProgress('progressing');        
        fetch(`${API_SERVER}/updateFixedPrice`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: 
                JSON.stringify({
                    SKU: sku,
                    FIXED_PRICE: price,
                })
        })
        .then((response) => {
            if (!response.ok) {
                setDataInProgress('error');
                throw new Error('Failed to update reklama data');
            }
            return response.text(); 
        })
        .then(() => {            
            setSearchKods('');
            setSearchName('');
            setDataInProgress('success');
        })
        .catch((error) => {
            console.error(error);
            setDataInProgress('error'); 
        });
};


    return (
        <>
        <Helmet>
        <meta name="robots" content="noindex, nofollow" />
        </Helmet>
        {isAdmin ? (
            <Tabs
                defaultActiveKey={defaultTab}
                id="justify-tab-example"
                className="mb-3"
                justify
                style={{ maxWidth: "95%", margin: "0 auto" }}
            >
                <Tab eventKey="Cenas" title="Cenas">
                    {DataInProgress === 'progressing' ? (
                        <div style={{ color: 'black', fontSize: "20px", fontWeight: "bold" }}>Notiek datu raktīšana datubāzē</div>
                    ) : DataInProgress === 'success' ? (
                        <div style={{ color: 'green', fontSize: "20px", fontWeight: "bold" }}>Dati datubāzē ierakstīti veiksmīgi</div>
                    ) : DataInProgress === 'error' ? (
                        <div style={{ color: 'red', fontSize: "20px", fontWeight: "bold" }}>Kļūda ierakstot datus</div>
                    ) : null}
                    <table>
                        <thead>
                            <tr>
                                <td>Kategorija</td>
                                <td>Kategorija2</td>
                                <td>Kategorija3</td>
                                <td>Piegādātājs</td>
                                <td>Uzcenojums</td>
                                <td>Min.Uzcenojums (EUR)</td>
                            </tr>
                            <tr key={"search-row"}>
                                <td><input placeholder='Meklēt..' value={SearchText} onChange={(e) => setSearchText(e.target.value)}></input></td>
                                <td><input placeholder='Meklēt..' value={SearchText2} onChange={(e) => setSearchText2(e.target.value)}></input></td>
                                <td><input placeholder='Meklēt..' value={SearchText3} onChange={(e) => setSearchText3(e.target.value)}></input></td>
                                <td><input placeholder='Meklēt..' value={SearchText4} onChange={(e) => setSearchText4(e.target.value)}></input></td>
                                <td>{userSearch.length >= 1 ? <button onClick={handleUzcSubmit}>Atjaunot visus datus</button> : null}</td>
                            </tr>
                        </thead>
                        <tbody>
                            {userSearch.length > 0 ? userSearch.map((item, index) => (
                                <tr key={`userSearch_${index}_${item.MENU_2}`}>
                                    <td>{item.MENU_1}</td>
                                    <td>{item.MENU_2}</td>
                                    <td>{item.MENU_3}</td>
                                    <td>{item.Loc}</td>
                                    <td>
                                        <input
                                            placeholder={item.UZC}
                                            type='number'
                                            step={0.01}
                                            onChange={(e) => handleUzcChange(item.MENU_2, item.MENU_3, item.Loc, e.target.value)} />
                                    </td>
                                    <td>
                                        <input
                                            placeholder={item.MINUZC}
                                            type='number'
                                            step={0.01}
                                            onChange={(e) => handleMinUzcChange(item.MENU_2, item.MENU_3, item.Loc, e.target.value)} />
                                    </td>
                                    
                                </tr>
                            )) :
                                prices.map((item, index) => (
                                    <tr key={`prices_${index}_${item.MENU_1}`}>
                                        <td>{item.MENU_1}</td>
                                        <td>{item.MENU_2}</td>
                                        <td>{item.MENU_3}</td>
                                        <td>{item.Loc}</td>
                                        <td>{item.UZC}</td>
                                        <td>{item.MINUZC}</td>
                                    </tr>
                                ))}
                        </tbody>
                    </table>
                </Tab>
                <Tab eventKey="Pasūtījumi" title="Pasūtījumi">
                    <>
                        {createInvoiceTable("new", "paymentOK", "Apmaksa saņemta", "rejected", "Noraidīt (nav preču)", "noPayment", "Noraidīt (nav apmaksāts)")}
                        {createInvoiceTable("paymentOK", "rejected", "Noraidīt pasūtījumu", "completed", "Nosūtīts", "", "")}
                        {createInvoiceTable("completed", "", "", "", "", "", "")}
                        {createInvoiceTable("rejected", "", "", "", "", "", "")}
                    </>
                </Tab>
                <Tab eventKey="Reklāma" title="Reklāma">
                    <div>
                        <div style={{ maxWidth: "1500px", margin: "20px auto", overflowX: "auto" }}>
                            <table style={{ width: "100%", borderCollapse: "collapse" }}>
                                <thead>
                                    <tr>
                                        <th style={{ fontSize: "18px", padding: "8px", border: "1px solid #ddd", width: "50px" }}>Reklāmas novietojums</th>
                                        <th style={{ fontSize: "18px", padding: "8px", border: "1px solid #ddd" }}>Faila nosaukums</th>
                                        <th style={{ fontSize: "18px", padding: "8px", border: "1px solid #ddd" }}>Faila links</th>
                                        <th style={{ fontSize: "18px", padding: "8px", border: "1px solid #ddd" }}>Apraksts</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {reklama.map((item, index) => (
                                        <tr key={index}>
                                            <td style={{ fontSize: "18px", padding: "8px", border: "1px solid #ddd", width: "100px"}}>{
                                            item.ReklamasVeids === "Left1" ? "Pa Kreisi augšējā":
                                            item.ReklamasVeids === "Left2" ? "Pa Kreisi vidējā":
                                            item.ReklamasVeids === "Left3" ? "Pa Kreisi apakšējā":
                                            item.ReklamasVeids === "Right1" ? "Pa Labi augšējā":    
                                            item.ReklamasVeids === "Right2" ? "Pa Labi vidējā":
                                            item.ReklamasVeids === "Right3" ? "Pa Labi apakšējā":
                                            item.ReklamasVeids === "Mobile1" ? "Mobilajā versijā 1":
                                            item.ReklamasVeids === "Mobile2" ? "Mobilajā versijā 2":
                                            item.ReklamasVeids === "Mobile3" ? "Mobilajā versijā 3":
                                            item.ReklamasVeids === "Main1" ? "Galvenajā lapā pa labi 1":
                                            item.ReklamasVeids === "Main3" ? "Galvenajā lapā pa labi 3":
                                            item.ReklamasVeids === "Main2" ? "Galvenajā lapā pa labi 2":
                                            item.ReklamasVeids.includes("Karuselis") ? item.ReklamasVeids:
                                            null
                                            
                                        }
                                            </td>
                                            <td >
                                                <div style={{ display: "flex", width: 
                                                     "100%", alignItems: "center", justifyContent: "center"}}>
                                                    <select onChange={(e) => handleInputChange(index, 'FailaNosaukums1', e.target.value)}>
                                                    {item.FailaNosaukums1 && <option style={{color: "red"}}>{item.FailaNosaukums1}</option>}
                                                    <option value=""> </option>
                                                        {files.map((file, index) => (
                                                            <option value={file}>{file}</option>
                                                        ))}               
                                                    </select>
                                                </div>
                                            </td>
                                            <td style={{ fontSize: "18px", padding: "8px", border: "1px solid #ddd", width: "250px" }}>
                                            <input
                                                            type="text"
                                                            value={item.Faila1Links}
                                                            onChange={(e) => handleInputChange(index, 'Faila1Links', e.target.value)}
                                                            placeholder={item.Faila1Links}
                                                            style={{ width: "350px", fontSize: "13px" }}
                                                        />
                                            </td>
                                            <td style={{ fontSize: "18px", padding: "8px", border: "1px solid #ddd", width: "250px" }}>
                                            <input
                                                            type="text"
                                                            value={item.Apraksts}
                                                            onChange={(e) => handleInputChange(index, 'Apraksts', e.target.value)}
                                                            placeholder={item.Apraksts}
                                                            style={{ width: "300px", fontSize: "13px" }}
                                                        />
                                            </td>

                                            
                                        </tr>
                                    ))}
                                    <input 
                                                        type="file"
                                                        onChange={(e) => handleFileInputChange(e)}
                                                        style={{ width: "250px", fontSize: "12px" }}
                                                    />
                                </tbody>
                            </table>
                            <button
                                onClick={handleReklamaChanged}
                                className="logIn"

                            >
                                Saglabāt izmaiņas
                            </button>
                        </div>
                    </div>
                </Tab>
                <Tab eventKey="Īpašie piedāvājumi" title="Īpašie piedāvājumi">
                <select value={activeCompany} onChange={(e) => {setActiveCompany(e.target.value);  }}>
                        <option>Izvēlieties uzņēmumu</option>
                        {companyList.map((item, index) => (
                            <option key={index} value={item}>{item}</option>
                        ))}
                    </select>
                    <div>   
                        {showSpecialOffer.length >=1  &&
                            <table style={{ width: "100%", borderCollapse: "collapse" }}>
                                <thead>
                                    <tr>
                                        <td>SKU</td>
                                        <td>Nosaukums</td>
                                        <td>Cena (ar PVN)</td>
                                        <td>Iepirkuma cena (ar PVN)</td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {showSpecialOffer.map((item, index) => (
                                    <tr key={index}>
                                        <td>{item.sku}</td>
                                        <td>{item.name}</td>
                                        <td><input placeholder={`${item.price}`} id={`${index}`} ></input></td>
                                        <td>{item.pprice*1.21.toFixed(2)}</td>
                                        <td><button onClick={() => addNewSpecialOffer(item.sku, document.getElementById(`${index}`).value, item.company)}>Apstiprināt</button></td>
                                        <td><button onClick={() => deleteSpecialOffer(item.sku, item.company)}>Dzēst</button></td>
                                    </tr>))}
                                </tbody>
                            </table>
                        }
                        {activeCompany !== 'Izvēlieties uzņēmumu' && activeCompany !== null ?
                        <button onClick={()=>setNewOfferLine(true)}>Pievienot jaunu</button>
                        : null}
                        {newOfferLine ?
                        <table style={{ width: "100%", borderCollapse: "collapse" }}>
                            <thead>
                                <tr>
                                    <td>SKU</td>
                                    <td>Nosaukums</td>
                                    <td>Cena (ar PVN)</td>
                                    <td>Iepirkuma cena (ar PVN)</td>
                                    <td></td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td><input onChange={(e) => searchSKU(e.target.value)}></input></td>
                                    {activeSku ? 
                                    <>
                                        <td>{activeSku.NAME}</td>
                                        <td><input placeholder={`${activeSku.PRICE}`} id="specialOfferInput"></input></td>
                                        <td>{activeSku.PPRICE*1.21.toFixed(2)}</td>
                                        <td><button onClick={() => addNewSpecialOffer(activeSku.SKU, document.getElementById('specialOfferInput').value, activeCompany)}>Pievienot</button></td>

                                    </>
                                    :
                                    <>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td><button>Apstiprināt</button></td>
                                    </>
                                    }
                                </tr>
                            </tbody>
                        </table>
                        : null}
                    </div>
                </Tab>
                <Tab eventKey="Uzstādījumi" title="Uzstādījumi">
                    <table style={{ width: "100%", borderCollapse: "collapse" }}>
                                    <thead>
                                        <tr>
                                            <td>Uzstādījums</td>
                                            <td>Cena (ar PVN)</td>
                                            <td>Svars (kg)</td>
                                            <td>Apstiprināt</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {settings.length >0 && 
                                        settings.map
                                        ((item, index) => (
                                            <tr key={index}>
                                                <td>{item.Setting === 'DpdP' ? 'DPD Pakomāts': item.Setting === 'OmnivaP' ? "Omniva pakomāts": item.Setting === "DpdK" ?  "DPD kurjera piegāde": item.Setting === "SmallOrderFeeLimit" ? "Mazs pasūtījums līdz":
                                                item.Setting === "SmallOrderFee" ? "Maza pasūtījuma cena" : item.Setting === "MaxSvarsPakomats" ?  "Maksimālais svars uz pakomātu (kg)" :
                                                item.Setting.includes('Solis') ? "Kurjera piegāde līdz.. kg" : "Bezmaksas piegāde no"}</td>
                                                <td><input placeholder={item.Setting.includes('Solis') ? item.Cena : item.Value} id={`${index}`}></input></td>
                                                <td>{item.Setting.includes('Solis') ? <input placeholder={item.Value} id={`${index}C`}></input>: ""}</td>
                                                <td><button onClick={() => {adjustSettings(item.Setting, document.getElementById(`${index}`).value, item.Setting.includes('Solis') && document.getElementById(`${index}C`).value)}}>Apstiprināt</button></td>    
                                            </tr>
                                        ))}
                                    </tbody>
                                    
                    </table>
                         

                </Tab>
                <Tab eventKey="Fiksētas cenas" title="Fiksētas cenas">
                {DataInProgress === 'progressing' ? (
                        <div style={{ color: 'black', fontSize: "20px", fontWeight: "bold" }}>Notiek datu raktīšana datubāzē</div>
                    ) : DataInProgress === 'success' ? (
                        <div style={{ color: 'green', fontSize: "20px", fontWeight: "bold" }}>Dati datubāzē ierakstīti veiksmīgi</div>
                    ) : DataInProgress === 'error' ? (
                        <div style={{ color: 'red', fontSize: "20px", fontWeight: "bold" }}>Kļūda ierakstot datus</div>
                    ) : null}
                <div>Meklēt produktu</div>
                <table>
                        <thead>
                            <tr>
                                <td>Kods</td>
                                <td>Nosaukums</td>                                
                            </tr>
                            <tr key={"search-row"}>
                                <td><input placeholder='Meklēt kodu' value={SearchKods} onChange={(e) => setSearchKods(e.target.value)}></input></td>
                                <td><input placeholder='Meklēt nosaukumu' value={SearchName} onChange={(e) => setSearchName(e.target.value)}></input></td>                                
                            </tr>
                        </thead>
                        {fixedSearch.length > 0 ?  
                        <tbody>
                            <td>Kods</td>
                            <td>Nosaukums</td>
                            <td>Pēdējā kategorija</td>
                            <td>Izcelsme</td>
                            <td>Iepirkuma cena (ar PVN)</td>
                            <td>Pašreizējā realizācijas cena</td>
                            <td>Fiksēta cena</td>
                            {allowFixPriceInput && (
                                <td>Fiksētā cena (ar PVN)</td>
                            )}                                                        
                            {fixedSearch.map((item, index) => (
                                item.FIXED_PRICE === null ? (
                                <tr key={`userSearch_${index}_${item.MENU_2}`}>
                                    <td>{item.SKU}</td>
                                    <td>{item.NAME}</td>
                                    <td>{item.MENU_3 !== "N/A" ? item.MENU_3: item.MENU_2}</td>
                                    <td>{item.Loc}</td>                                    
                                    <td>{(Number(item.PPRICE)*1.21).toFixed(2)}</td>
                                    <td>{item.PRICE}</td>
                                    <td><input
                                        type="checkbox"
                                        id="terms"
                                        name="terms"
                                        value="terms"
                                        required
                                        onChange={handleFixPriceChange}                                        
                                    />                                      
                                    </td>
                                    {allowFixPriceInput && (
                                        <td>
                                            <input
                                                placeholder={item.FIXED_PRICE}
                                                type='number'
                                                step={0.01}                                                
                                                onChange={(e) => setNewFixedPrice(e.target.value)} />
                                        </td>
                                    )}
                                    {newFixedPrice > 0 && (
                                        <td>
                                            <button onClick={() => handleFixPriceSubmit(item.SKU, newFixedPrice)}>Pievienot</button>
                                        </td>
                                    )}                                    
                                </tr>)
                                : "Šim produktam jau ir fiksētā cena"
                            ))}
                        </tbody>
                        : null}
                    </table>
                    {fixedPriceProducts.length > 0 && (
                        <>
                    <div>Esošie produkti ar fiksētām cenām</div>
                    <table>
                        <thead>
                            <tr>
                                <td>Kods</td>
                                <td>Nosaukums</td>
                                <td>Pēdējā kategorija</td>
                                <td>Izcelsme</td>
                                <td>Iepirkuma cena (ar PVN)</td>
                                <td>Fiksēta cena (ar PVN)</td>
                                <td></td>
                            </tr>
                        </thead>
                        <tbody>
                            {fixedPriceProducts.map((item, index) => (
                                <tr key={`fixedPrices_${index}_${item.SKU}`}>
                                    <td>{item.SKU}</td>
                                    <td>{item.NAME}</td>
                                    <td>{item.MENU_3 !== "N/A" ? item.MENU_3: item.MENU_2}</td>
                                    <td>{item.Loc}</td>
                                    <td>{(Number(item.PPRICE)*1.21).toFixed(2)}</td>
                                    <td>{item.FIXED_PRICE}</td>
                                    <td>
                                        <button onClick={() => handleFixPriceSubmit(item.SKU, null)}>Dzēst</button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    </>
                    )}
                
                </Tab>
            </Tabs>
        ) : null}
        </>
    );
}

export default Panel;