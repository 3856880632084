import { createUserWithEmailAndPassword } from "firebase/auth";
import useFirebase from "../../firebase/usefirebase";
import React, {useEffect, useState} from "react";



const SignUp = () => {
    const [email, setEmail] = useState(null);
    const [password, setPassword] = useState(null);  
    const [confirmPassword, setConfirmPassword] = useState(null);
    const [customerType, setCustomerType] = useState("private");
    const [name, setName] = useState(null);
    const [surname, setSurname] = useState(null);
    const [companyName, setCompanyName] = useState(null);
    const [vatNumber, setVatNumber] = useState(null);
    const [phone, setPhone] = useState(null);//Kontakttālrunis
    const [regNumber, setRegNumber] = useState(null);
    const [legalAddress, setLegalAddress] = useState(null);
    const [wrongPassword, setWrongPassword] = useState(null);
    const [error, setError] = useState(null);
    const [allSubmited, setAllSubmited] = useState(null);
    const { auth } = useFirebase();
    const API_SERVER = process.env.REACT_APP_API_SERVER;

    



    async function Register(e) {
        e.preventDefault();
        if (password !== confirmPassword) {
            setWrongPassword(true);
            return;
        }
    
        try {
            await createUserWithEmailAndPassword(auth, email, password);
            const response = await fetch(`${API_SERVER}/newuser`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    name: name,
                    surname: surname,
                    type: customerType,
                    companyName: companyName,
                    VAT: vatNumber,
                    email: email,
                    Phone: phone,
                    RegNumber: regNumber,
                    legalAddress: legalAddress,
                })
            });
    
            if (!response.ok) {
                const errorData = await response.json();
                console.error('Server error:', errorData.message);
                return;
            }
            setEmail("");
            setConfirmPassword("");
            setPassword("");
            setName("");
            setSurname("");
            setCompanyName("");
            setVatNumber("");
            setPhone("");
            setRegNumber("");
            setLegalAddress("");
            window.location.href = "./categories";
        } catch (error) {
            const errorCode = error.code;
            if (errorCode === "auth/email-already-in-use") {
                setError("Lietotājs ar šādu epastu jau ir reģistrēts");
            } else {
                console.error("Error registering user:", error.message);
            }
        }
    }
    

    

    useEffect (() => {
        if (customerType === "private") {
            if (name !== null && name !== "" &&
                surname !== null && surname !== "" &&
                email !== null && email !== "" &&
                password !== null && password !== "" &&
                confirmPassword !== null && confirmPassword !== "" &&
                phone !== null && phone !== "") {
                setAllSubmited(true);
            } else {
                setAllSubmited(false);
            }
        } else {
            if (name !== null && name !== "" &&
                surname !== null && surname !== "" &&
                email !== null && email !== "" &&
                password !== null && password !== "" &&
                confirmPassword !== null && confirmPassword !== "" &&
                phone !== null && phone !== "" &&
                companyName !== null && companyName !== "" &&
                regNumber !== null && regNumber !== "" &&
                legalAddress !== null && legalAddress !== "") {
                setAllSubmited(true);
            } else {
                setAllSubmited(false);
            }
        }
    }, [name, surname, email, password, confirmPassword, phone, companyName, regNumber, legalAddress, customerType]);


    return (
        <div>
            <form onSubmit={Register}>
                <div className="input-msg2">
                    <input type="text" value={name} placeholder="Vārds" onChange={(e) => setName(e.target.value)} required />
                </div>
                <div className="input-msg2">
                    <input type="text" placeholder="Uzvārds" value={surname} onChange={(e) => setSurname(e.target.value)} required />
                </div>
                <div className="input-msg2">
                    <input type="text" placeholder="E-pasts" value={email} onChange={(e) => setEmail(e.target.value)} required />
                </div>
                <div className="input-msg2">
                    <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} required  pattern="^(?=.*[a-z])(?=.*[A-Z]).{6,20}$"  placeholder="Parole (6-20 simboli, Lielie un mazie burti)"/>
                </div>
                {wrongPassword && <p style={{ color: "red" }}>Jūsu ievadītās paroles nesakrīt!</p>}
                {error && <p style={{ color: "red" }}>{error}</p>}
                <div className="input-msg2">
                    <input type="password" placeholder="Atkārtojiet paroli"value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} required />
                </div>
                <div className="input-msg2">
                    <input
                        type="text"
                        value={phone}
                        placeholder="Kontakttālrunis"
                        onChange={(e) => {
                            const value = e.target.value;
                            if (/^\d{0,8}$/.test(value)) {
                                setPhone(value);
                            }
                        }}
                        required
                        pattern="\d{8}"
                        maxLength="8"
                        minLength="8"
                        title="Kontakttālrunim jābūt precīzi 8 ciparu garam."
                    />
                </div>
                <div className="radio-select">
                    <label>
                        <input type="radio" value="private" checked={customerType === "private"} onChange={() => setCustomerType("private")} />
                        <span className="radio-custom"></span>
                        Privātpersona
                    </label>
                    <label>
                        <input type="radio" value="company" checked={customerType === "company"} onChange={() => setCustomerType("company")} />
                        <span className="radio-custom"></span>
                        Juridiska persona
                    </label>
                </div>
                {customerType === "company" && (
                    <div>
                        <div className="input-msg2">
                        <div className="new-profile-text">Uzņēmuma rekvizīti</div>
                        </div>
                        <div className="input-msg2">
                            <input type="text" placeholder="Uzņēmuma nosaukums" value={companyName} onChange={(e) => setCompanyName(e.target.value)} required />
                        </div>
                        <div className="input-msg2">
                            <input type="text" value={regNumber} placeholder="Reģistrācijas Nr." onChange={(e) => setRegNumber(e.target.value)} required />
                        </div>
                        <div className="input-msg2">
                            <input type="text" value={vatNumber} placeholder="PVN Reģistrācijas Nr." onChange={(e) => setVatNumber(e.target.value)} />
                        </div>
                        <div className="input-msg2">
                           <input type="text" value={legalAddress} placeholder="Juridiskā adrese" onChange={(e) => setLegalAddress(e.target.value)} required />
                        </div>
                    </div>
                )}
                {allSubmited ? 
                <div className="input-msg2">
                    <button className="logIn" type="submit">Reģistrēties</button>
                </div>
                :
                <div className="input-msg2">
                            <button className="logIn" style={{ color: "black", backgroundColor: "#e8e8e8" }}>Reģistrēties</button>
                        </div>
                }
            </form>


        </div>
    )
}

export default SignUp;
