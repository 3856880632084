import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import "./side.css";
import { set } from "date-fns";



const Mobile = () => {
    const [mobileImage1, setMobileImage1] = useState();
    const [mobileImage2, setMobileImage2] = useState();
    const [mobileImage3, setMobileImage3] = useState();
    const [link1, setLink1] = useState();
    const [link2, setLink2] = useState();
    const [link3, setLink3] = useState();
    const [apraksts1, setApraksts1] = useState();
    const [apraksts2, setApraksts2] = useState();
    const [apraksts3, setApraksts3] = useState();
    const [addToShow, setAddToShow] = useState(1);
    const [aprakstsToShow, setAprakstsToShow] = useState(1); 
    const [linkToShow, setLinkToShow] = useState(1);
    const API_SERVER = process.env.REACT_APP_API_SERVER;

    
        
      
    
      

    useEffect(() => {
        fetch(`${API_SERVER}/adds?ReklamasVeids=Mobile`)
            .then((response) => {
                if (!response.ok) {
                    throw new Error("Failed to fetch left adds");
                }
                return response.json();
            })
            .then((data) => {
                data.mobile1.FailaNosaukums1 && setMobileImage1(data.mobile1.FailaNosaukums1);
                data.mobile1.Faila1Links && setLink1(data.mobile1.Faila1Links);
                data.mobile1.Apraksts && setApraksts1(data.mobile1.Apraksts);
                data.mobile2.FailaNosaukums1 && setMobileImage2(data.mobile2.FailaNosaukums1);
                data.mobile2.Faila1Links && setLink2(data.mobile2.Faila1Links);
                data.mobile2.Apraksts && setApraksts2(data.mobile2.Apraksts);
                data.mobile3.FailaNosaukums1 && setMobileImage3(data.mobile3.FailaNosaukums1);
                data.mobile3.Faila1Links && setLink3(data.mobile3.Faila1Links);
                data.mobile3.Apraksts && setApraksts3(data.mobile3.Apraksts);
            })
            .catch((error) => console.error(error));
    }, []);

    useEffect(() => {
        if (mobileImage1 && mobileImage2 && mobileImage3) {
            let toShow = Math.floor(Math.random() * 3) + 1;
            switch (toShow) {
                case 1:
                    setAddToShow(mobileImage1);
                    setAprakstsToShow(apraksts1);
                    setLinkToShow(link1);
                    break;
                case 2:
                    setAddToShow(mobileImage2);
                    setAprakstsToShow(apraksts2);
                    setLinkToShow(link2);
                    break;
                case 3:
                    setAddToShow(mobileImage3);
                    setAprakstsToShow(apraksts3);
                    setLinkToShow(link3);
                    break;
                default:
                    setAddToShow(false);
                    setAprakstsToShow(false);
                    setLinkToShow(false);
                    break;
            }
          } else if (mobileImage1 && mobileImage2) {
            let toShow = Math.floor(Math.random() * 2) + 1;
            switch (toShow) {
                case 1:
                    setAddToShow(mobileImage1);
                    setAprakstsToShow(apraksts1);
                    setLinkToShow(link1);
                    break;
                case 2:
                    setAddToShow(mobileImage2);
                    setAprakstsToShow(apraksts2);
                    setLinkToShow(link2);
                    break;
                default:
                    setAddToShow(false);
                    setAprakstsToShow(false);
                    setLinkToShow(false);
                    break;
            }
          } else if (mobileImage1) {
            setAddToShow(mobileImage1);
            setAprakstsToShow(apraksts1);
            setLinkToShow(link1);
          } else {
            setAddToShow(false);
            setAprakstsToShow(false);
            setLinkToShow(false);
          }
    }, [mobileImage1, mobileImage2, mobileImage3]);
    
    return (
        <div className="mobile">
            {linkToShow && addToShow ? (
                <Link to={`/${linkToShow}`}>
                    <img src={`${process.env.PUBLIC_URL}/ReklamasFoto/${addToShow}`} alt={`${aprakstsToShow}`} />
                </Link>
            ) : addToShow ? (
                <img src={`${process.env.PUBLIC_URL}/ReklamasFoto/${addToShow}`} alt={`${aprakstsToShow}`} />
            ) : null
            }
        </div>
    )
}

export default Mobile;