import React, { useEffect, useState } from 'react';

import './carousel.css';

import { GetItems } from '..//../ProdHandleLogic.js';
import ProductCard from '..//../productCard/ProductCard.js';

const ProductSlider = () => {
    const [products, setProducts] = useState([]);
  
    useEffect(() => {      
      const getProducts = async () => {
        const productData = await GetItems('MainPage', null, null, null);
        setProducts(productData.products);
      };
      getProducts();
    }, []);
    
    const loopingProducts = [...products, ...products];
    return (
      <div className="slider-container">
        <div className="slider-track">
          {loopingProducts.map((item, index) => (            
            <ProductCard
            key={index}
            name={item.NAME}
            price={item.PRICE}
            quantity={item.QTY}
            minorder={item.MINORDER ? item.MINORDER : 1}
            discount={item.DISCOUNT}
            link={
                item.MENU_3 === 'N/A'
                    ? `/categories/${encodeURIComponent(item.MENU_1).replace(
                          /%20/g,
                          '-'
                      )}/${encodeURIComponent(item.MENU_2).replace(
                          /%20/g,
                          '-'
                      )}/${item.SKU}`
                    : `/categories/${encodeURIComponent(item.MENU_1).replace(
                          /%20/g,
                          '-'
                      )}/${encodeURIComponent(item.MENU_2).replace(
                          /%20/g,
                          '-'
                      )}/${encodeURIComponent(item.MENU_3).replace(
                          /%20/g,
                          '-'
                      )}/${item.SKU}`
            }
            image={item.MENU_2 === "Officeshop ražotie kārtridži" ? `/prodImages/Small/Officeshop Cartridges (Small)/cartridges (Small).jpg` : item.MENU_1 === 'Kopējošās lentas (ribbon)' ?`/prodImages/Small/Ribbon (Small)/ribbon (Small).jpg`: item.PICTURE && item.PICTURE.startsWith('http') ? item.PICTURE : item.PICTURE ? `/prodImages/Small/${item.SKU} (Small)/${item.SKU} (Small).${item.PICTURE}` : `/prodImages/Small/logo (Small)/logo (Small).png`}
            sku={item.SKU}
            
            // fav={favorites.includes(item.SKU) ? 'yes' : 'no'}
            // onDeleteFav={() => { updateFavoritesAfterDelete(); }}
          />            
          ))}
        </div>
      </div>
    );
  };
  
  export default ProductSlider;