import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import ProductCard from '../productCard/ProductCard.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Pagination } from 'react-bootstrap/';
import { Helmet } from 'react-helmet';
import useFirebase from "../../firebase/usefirebase";
import { onAuthStateChanged } from "firebase/auth";




const FullSearchResults = () => {
  const location = useLocation();
  const [results, setResults] = useState([]);  
  const [favorites, setFavorites] = useState([]);
  const [email, setEmail] = useState("");
  const [productsPerPage, setProductsPerPage] = useState(15);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [authLoading, setAuthLoading] = useState(true);
  const { auth, user } = useFirebase();
  const navigate = useNavigate();
  const query = new URLSearchParams(location.search);
  const currentPage = parseInt(query.get('page') || '1', 10);
  const totalPages = Math.ceil(selectedProducts.length / productsPerPage);
  const maxPagesToShow = 7;
  const API_SERVER = process.env.REACT_APP_API_SERVER;

  useEffect(() => {
    const updateProductsPerPage = () => {
      if (window.innerWidth < 768) {
        setProductsPerPage(16);
      } else {
        setProductsPerPage(15);
      }
    };
    updateProductsPerPage();
    window.addEventListener('resize', updateProductsPerPage);
    if (auth) {
      const unsubscribe = onAuthStateChanged(auth, (user) => {
          if (user) {
              setEmail(user.email);
              fetch(`${API_SERVER}/favorites?Email=${user.email}`)
              .then((response) => {
                if (!response.ok) {
                  throw new Error("Failed to fetch user data");
                }
                return response.json();
              })
              .then((data) => {
                const favoritesString = data[0].Favorites;
                const favoritesArray = favoritesString.split(",");
                setFavorites(favoritesArray);
              })
              .catch((error) => console.error(error))
              .finally(() => {
                setAuthLoading(false);
              });

          } else {
            setAuthLoading(false); 
          }
          const queryParams = new URLSearchParams(location.search);
      const data = queryParams.get('data');
      if (data) {
        setResults(JSON.parse(decodeURIComponent(data)));
      } else {
        console.error('No search results found');
      }
      if (!query.get('page')) {
        paginate(1);
      }
      });
      return () => {
          window.removeEventListener('resize', updateProductsPerPage);
          unsubscribe();
      };
  } else {
    setAuthLoading(false); 
  }
}, [API_SERVER, auth]);


  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const data = queryParams.get('data');
    if (data) {
      setResults(JSON.parse(decodeURIComponent(data)));
    } else {
      console.error('No search results found');
    }
    if (!query.get('page')) {
      paginate(1);
    }
  }, [location]);

  useEffect(() => {
    if (authLoading) return;
    !Array.isArray(results) &&
    fetch(`${API_SERVER}/search?search=${results}&full=true`)
            .then((response) => {
                if (!response.ok) {
                    throw new Error("Failed to fetch user data");
                }
                return response.json();
            })
            .then((data) => {
                setSelectedProducts(data.item);
            })
            .catch((error) => console.error(error));
}, [results, authLoading, API_SERVER]);

 
  const updateFavoritesAfterDelete = () => {
    fetch(`${API_SERVER}/favorites?Email=${email}`)
            .then((response) => {
              if (!response.ok) {
                throw new Error("Failed to fetch user data");
              }
              return response.json();
            })
            .then((data) => {
              const favoritesString = data[0].Favorites;
              const favoritesArray = favoritesString.split(",");
              setFavorites(favoritesArray);
            })
            .catch((error) => console.error(error));
  };
  
   
   const indexOfLastProduct = currentPage * productsPerPage;
   const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
   const currentProducts = selectedProducts.slice(
     indexOfFirstProduct,
     indexOfLastProduct
   );
 
   const paginate = (pageNumber) => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
    query.set('page', pageNumber);
    navigate({ search: query.toString() });
  }

  
  

  let startPage = Math.max(1, currentPage - Math.floor(maxPagesToShow / 2));
  let endPage = startPage + maxPagesToShow - 1;

  const renderPaginationItems = () => {
    const items = [];
    let startPage = Math.max(1, currentPage - Math.floor(maxPagesToShow / 2));
    let endPage = startPage + maxPagesToShow - 1;

    if (endPage > totalPages) {
      endPage = totalPages;
      startPage = Math.max(1, endPage - maxPagesToShow + 1);
    }

    for (let page = startPage; page <= endPage; page++) {
      items.push(
        <Pagination.Item
          key={page}
          active={page === currentPage}
          onClick={() => paginate(page)}
        >
          {page}
        </Pagination.Item>
      );
    }

    return items;
  };

  const getNextDayDate = () => {
    const now = new Date();
    const nextDay = new Date(now);
    nextDay.setDate(now.getDate() + 1); 
    nextDay.setHours(23, 59, 0); 
    return nextDay.toISOString().split('T')[0] + 'T23:59:00'; 
  };
  
  let jsonLD;
  if (selectedProducts.length === 0) {
    jsonLD = {
      "@context": "https://schema.org",
      "@type": "SearchResultsPage",
      "name": `${results} - preces no Officeshop.lv`,
      "description": `${results} - labas cenas, ātra piegāde, izvēlies no vairāk kā 100'000 precēm | Officeshop.lv`,      
      "mainEntity": {
        "@type": "ItemList",
        "itemListElement": []
      }
    };
  } else {
    jsonLD = {
      "@context": "https://schema.org",
      "@type": "SearchResultsPage",
      "name": `${results} - Meklēšanas Rezultāti | Officeshop.lv`,
      "description": `"${results}  pie OfficeShop.lv! plašs sortiments, labas cenas, ātra piegāde, izvēlies no vairāk kā 100'000 precēm | Officeshop.lv`,      
      "potentialAction": {
        "@type": "SearchAction",
        "target": `https://www.officeshop.lv/search-results?data=${encodeURIComponent(JSON.stringify(results))}`,
        "query-input": "required name=search_term_string"
      },
      "mainEntity": {
        "@type": "ItemList",
        "itemListElement": selectedProducts.map((product, index) => ({
          "@type": "Product",
          "name": product.NAME,
          "image": product.MENU_2 === "Officeshop ražotie kārtridži" ? `https://www.officeshop.lv/prodImages/Small/Officeshop Cartridges (Small)/cartridges (Small).jpg` :
            product.MENU_1 === "Kopējošās lentas (ribbon)" ? `https://www.officeshop.lv/prodImages/Small/Ribbon (Small)/ribbon (Small).jpg` :
              product.PICTURE && product.PICTURE.startsWith('http') ? product.PICTURE : product.PICTURE ? `https://www.officeshop.lv/prodImages/Small/${product.SKU} (Small)/${product.SKU} (Small).${product.PICTURE}` : `https://www.officeshop.lv/prodImages/Small/logo (Small)/logo (Small).png`,
          "description": product.NAME + '. Pasūti tagad! Ātra piegāde un iepriecinošas cenas. Izvēlies no vairāk kā 100`000 precēm! www.OfficeShop.lv',
          "brand": product.BRAND !== null ? product.BRAND : "OfficeShop",
          "offers": {
            "@type": "Offer",
            "price": product.PRICE,
            "priceCurrency": "EUR",
            'priceValidUntil': getNextDayDate(),
            "availability": product.QTY > 0 ? "InStock" : "OutOfStock",
            "hasMerchantReturnPolicy": {
              "@type": "MerchantReturnPolicy",
              "returnPolicyCategory": "https://schema.org/ReturnAllowed",
              "applicableCountry": "LV",
              "returnPolicySeasonalOverride": "false",
              "merchantReturnDays": 14
            },
            "shippingDetails": {
              "@type": "OfferShippingDetails",
              "shippingDestination": {
                "@type": "DefinedRegion",
                "name": "Latvia"
              },
              "deliveryTime": {
                "@type": "ShippingDeliveryTime",
                "businessDays": 3
              }
            }
          },
          "url": `${product.MENU_3 === "N/A"
            ? `/categories/${encodeURIComponent(product.MENU_1).replace(/%20/g, '-')}/${encodeURIComponent(product.MENU_2).replace(/%20/g, '-')}/${product.SKU}`
            : `/categories/${encodeURIComponent(product.MENU_1).replace(/%20/g, '-')}/${encodeURIComponent(product.MENU_2).replace(/%20/g, '-')}/${encodeURIComponent(product.MENU_3).replace(/%20/g, '-')}/${product.SKU}`
            }`
        }))
      }


    };
  }

  return (
    <>
      <Helmet>
        <title>{`${results} - Meklēšanas Rezultāti | Officeshop.lv`}</title>
        <meta name="description" content={`Meklēšanas rezultāti "${results}" on Officeshop.lv`} />
        <meta name="robots" content="index, follow" />
        <script type="application/ld+json">{JSON.stringify(jsonLD)}</script>
      </Helmet>
      <p className='SearchResultsName'>Meklēšanas rezultāti frāzei: {results}</p>
      {selectedProducts.length > 0 && (
          <>
            <div className="product-page">
              {currentProducts.map((item, index) => (
                <div key={index} className="product">
                  <ProductCard
                    key={item.SKU}
                    name={item.NAME}
                    price={item.PRICE}
                    quantity={item.QTY}
                    discount={item.DISCOUNT}
                    currentPage={currentPage}
                    minorder={item.MINORDER ? item.MINORDER : 1}
                    link={
                      item.MENU_3 === "N/A"
                        ? `/categories/${encodeURIComponent(item.MENU_1).replace(/%20/g, '-')}/${encodeURIComponent(item.MENU_2).replace(/%20/g, '-')}/${item.SKU}?page=${currentPage}`
                        : `/categories/${encodeURIComponent(item.MENU_1).replace(/%20/g, '-')}/${encodeURIComponent(item.MENU_2).replace(/%20/g, '-')}/${encodeURIComponent(item.MENU_3).replace(/%20/g, '-')}/${item.SKU}?page=${currentPage}`
                    }
                    image={item.MENU_2 === "Officeshop ražotie kārtridži" ? '/prodImages/Small/Officeshop Cartridges (Small)/cartridges (Small).jpg' :
                      item.MENU_1 === "Kopējošās lentas (ribbon)" ? '/prodImages/Small/Ribbon (Small)/ribbon (Small).jpg' :
                      item.PICTURE ? item.PICTURE.startsWith('http') ? item.PICTURE: `/prodImages/Small/${item.SKU} (Small)/${item.SKU} (Small).${item.PICTURE}` :
                                  `/prodImages/Small/logo (Small)/logo (Small).png`}
                    sku={item.SKU}
                    fav={favorites.includes(item.SKU) ? 'yes' : 'no'}
                    onDeleteFav={() => { updateFavoritesAfterDelete(); }}
                  />
                </div>
              ))}
            </div> 
          {selectedProducts.length > productsPerPage ? (
            <Pagination className='custom-pagination'>
            <Pagination.First onClick={() => currentPage === 1 ? null : paginate(1)} />
            <Pagination.Prev onClick={() => currentPage === 1 ? null : paginate(currentPage - 1)} />
            {startPage > 1 && <Pagination.Ellipsis onClick={() => paginate(startPage - 1)} />}
            {renderPaginationItems()}
            {endPage < totalPages && <Pagination.Ellipsis onClick={() => paginate(endPage + 1)} />}
            <Pagination.Next onClick={() => currentPage === totalPages ? null : paginate(currentPage + 1)} />
            <Pagination.Last onClick={() => currentPage === totalPages ? null : paginate(totalPages)} />
          </Pagination>
          ) : null}
        </>
      )}
    </>
  );
};

export default FullSearchResults;
