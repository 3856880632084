
import { useState, useEffect } from 'react';
import { initializeApp } from 'firebase/app';
import { getAuth, onAuthStateChanged } from 'firebase/auth';

const useFirebase = () => {
  const [auth, setAuth] = useState(null);
  const [user, setUser] = useState(null);
  const API_SERVER = process.env.REACT_APP_API_SERVER;

  useEffect(() => {
    const fetchFirebaseConfig = async () => {
      try {
        const response = await fetch(`${API_SERVER}/fbconfig`); 
        if (!response.ok) {
          throw new Error('Failed to fetch Firebase config');
        }
        const data = await response.json();
        const firebaseConfig = {
          apiKey: data.apiKey,
          authDomain: data.authDomain,
          projectId: data.projectId,
          storageBucket: data.storageBucket,
          messagingSenderId: data.messagingSenderId,
          appId: data.appId
        };
        const app = initializeApp(firebaseConfig);
        const auth = getAuth(app);
        setAuth(auth);

        const unsubscribe = onAuthStateChanged(auth, user => {
          if (user) {
            setUser(user);
          } else {
            setUser(null);
          }
        });

        return () => {
          unsubscribe();
        };

      } catch (error) {
        console.error('Error fetching Firebase config:', error);
      }
    };

    fetchFirebaseConfig();

  }, []);

  return { auth, user };
};

export default useFirebase;
