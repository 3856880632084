import React, { useState, useEffect, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { GrClose } from "react-icons/gr";
import { MdOutlineScreenSearchDesktop } from "react-icons/md";
import './cartridgeSearch.css';
import InputFormCartridgeSearch from '../Users/contactFormCartridgeSearch.js'
import { MdKeyboardDoubleArrowDown } from "react-icons/md";

function CartridgeSearch() {
    const [searchResults, setSearchResults] = useState([]);
    const searchContainerRef = useRef(null);
    const [allBrands, setAllBrands] = useState([]);
    const [brand, setBrand] = useState([]);
    const [printerList, setPrinterList] = useState(null);
    const [selectedPrinter, setSelectedPrinter] = useState([]);
    const [searchproductSKU, setSearchproductSKU] = useState([]);
    const [userSearch, setUserSearch] = useState(null);
    const [isActive, setIsActive] = useState(false);
    const [brandIsActive, setBrandIsActive] = useState(false);
    const [modelIsActive, setModelIsActive] = useState(false);
    const [showNotificaton, setShowNotificaton] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const location = useLocation();
    const API_SERVER = process.env.REACT_APP_API_SERVER;

  

    const toggleFunction = () => {
        setIsActive(!isActive);
        setBrandIsActive(false);
        setModelIsActive(false);
        //setSelectedPrinter([]);
        //setBrand([]);
        //setSearchResults([]);
        // document.getElementById('myInput').value = '': null;
    };

    const brandActive = () => {
        setBrandIsActive(!brandIsActive);
    };
    const modelActive = () => {
        setModelIsActive(!modelIsActive);
    };

    const handleSearch = (e) => {
        const searchValue = e.target.value;
        const filteredPrinters = printerList.filter((printer) =>
            printer.toLowerCase().includes(searchValue.toLowerCase())
        );
        setUserSearch(filteredPrinters);
    };
    
    
    

    useEffect(() => {
        if (selectedPrinter?.length > 0) {
            setShowNotificaton(false);
            fetch(`${API_SERVER}/cartridgeSearch?Model=${selectedPrinter}`)
                .then((response) => {
                    if (!response.ok) {
                        throw new Error("Failed to fetch user data");
                    }
                    return response.json();
                })
                .then((data) => {
                    setSearchproductSKU(data.map(item => item.SKU));
                })
                .catch((error) => console.error(error));
        }

    }, [selectedPrinter]);

    useEffect(() => {
        if (searchproductSKU?.length > 0) { 
            fetch(`${API_SERVER}/sku?skus=${searchproductSKU}`)
                    .then((response) => {
                        if (!response.ok) {
                            throw new Error("Failed to fetch user data");
                        }
                        return response.json();
                    })
                    .then((data) => {
                        setSearchResults(data);
                        if (data.length === 0) {
                            setShowNotificaton(true);
                        }
                    })
                    .catch((error) => console.error(error));
        }
    }, [searchproductSKU]);

    
    useEffect(() => {
        if (isActive || location.pathname==='/cartridges') {
        fetch(`${API_SERVER}/cartridgeSearch?Brand=all`)
                .then((response) => {
                    if (!response.ok) {
                        throw new Error("Failed to fetch user data");
                    }
                    return response.json();
                })
                .then((data) => {
                    setAllBrands(data.map(item => item.BRAND));
                    
                })
                .catch((error) => console.error(error));
        }
    }, [isActive]);

    useEffect(() => {
        if (brand?.length > 0) {
            fetch(`${API_SERVER}/cartridgeSearch?Brand=${brand}`)
                .then((response) => {
                    if (!response.ok) {
                        throw new Error("Failed to fetch user data");
                    }
                    return response.json();
                })
                .then((data) => {
                    setPrinterList(data.map(item => item.MODEL));
                    setSelectedPrinter([]);
                    setSearchResults([]);
                    setUserSearch(null);
                    document.getElementById('myInput').value = '';
                })
                .catch((error) => console.error(error));
        }
    }, [brand]);

    
    const showFullSearch = (e) => {
        e.preventDefault();
        localStorage.removeItem('cartridges');
        localStorage.removeItem('printerModel');
        localStorage.setItem('cartridges', JSON.stringify(searchResults));
        window.dispatchEvent(new Event('storage'));
        localStorage.setItem('printerModel', JSON.stringify(selectedPrinter));
        window.dispatchEvent(new Event('storage'));
        window.location.href = '/cartridgeSearchResults';
    }   

   
    return (
        <>  
            {location.pathname==='/cartridges' ?
            null : 
            <div onClick={toggleFunction} className="main-container">
            {window.innerWidth > 768 ? (
                <div className="virsraksts-special">
                    <MdOutlineScreenSearchDesktop className='thirdRowIcon' />
                    <div>
                        {['K', 'ā', 'r', 't', 'r', 'i', 'd', 'ž', 'u', ' ', 'm', 'e', 'k', 'l', 'ē', 't', 'ā', 'j', 's'].map((letter, index) => (
                           <span key={index} style={letter === ' ' ? { width: '5px' } : {}}>{letter}</span>
                        ))}
                    </div>
                </div>
            ) : (
                <div className="virsraksts">
                     <MdOutlineScreenSearchDesktop className='thirdRowIcon' />
                    <div>Kārtridžu meklētājs</div>
                </div>
            )}
            </div>
            }   
            {isActive ? <div className="Cover-container"> </div> : null}
            <div className={location.pathname==='/cartridges'? 'CartridgeSeachMainContainer' : `slide-in ${isActive ? 'active' : ''}`}>
                {location.pathname!=='/cartridges'? <div className='virsraksts-cartridge'>
                    <h2>Kārtridžu meklētājs</h2>
                    <GrClose onClick={toggleFunction} className='icon'>Aizvērt</GrClose>
                </div>
                :
                <div className='virsraksts-main-cartridge'>
                    <h2><MdKeyboardDoubleArrowDown color="#8BCB15" className="CartridgePageIcon" />Ētri atrodi savu kārtridžu!<MdKeyboardDoubleArrowDown color="#8BCB15" className="CartridgePageIcon" /></h2>
                </div>}

                <div className="search-container-cartridge" ref={searchContainerRef}>
                    {window.innerWidth <= 768 && (
                        <>
                            <div className='izvelne' onClick={brandActive}>{brand?.length > 0 ? brand : "Izvēlieties printera ražotāju"}</div>
                            {allBrands.map((brand) => (
                                <div
                                    value={brand}
                                    className={`saraksts ${brandIsActive ? 'active' : ''}`}
                                    onClick={(e) => {
                                        setBrand(e.target.getAttribute('value'));
                                        setSearchTerm("");
                                        brandActive();
                                    }}
                                >
                                    {brand}
                                </div>
                            ))}

                            {printerList && (
                                <>
                                    <input
                                        type="text"
                                        id="myInput"
                                        className="mekletajs active"
                                        placeholder={selectedPrinter.length > 0 ? selectedPrinter : "Sāciet rakstīt printera Nr. vai nosaukumu!"}
                                        value={searchTerm} 
                                        onChange={(e) => {
                                            setSearchTerm(e.target.value); 
                                            handleSearch(e);
                                            if (selectedPrinter) {
                                                setSelectedPrinter("");
                                            }
                                        }}
                                    />

                                    {userSearch && userSearch.slice(0, 20).map((brand, index) => (
                                        <div
                                            key={index}
                                            className="saraksts active"
                                            value={brand}
                                            onClick={(e) => {
                                                setSelectedPrinter(brand); 
                                                setSearchTerm(brand); 
                                                setUserSearch(null); 
                                                modelActive(); 
                                            }}
                                        >
                                            {brand}
                                        </div>
                                    ))}
                                </>
                            )}
                            {showNotificaton &&
                            <>
                            <p className='FoundProductCount' style={{textAlign: "center", width: "95%"}}>Diemžēl kārtridži nav atrasti, taču mums tie noteikti ir. Aizpildi savu kontaktinformāciju un mēs sagatavosim kārtridžu piedāvājumu Tavam printerim</p>
                            <InputFormCartridgeSearch printer={selectedPrinter}/>
                            </>
                            }
                            {searchResults.length > 0 && (
                                <div>
                                    <p className='PrinterNameSearchResults' style={{ marginBottom: "0px" }}>Kārtridži <strong>{selectedPrinter}</strong> printerim</p>
                                    <span className='FoundProductCount'>Atrastas preces: {searchResults.length}</span>
                                    <p className='calncelSearchText' onClick={(e) => { setSearchResults([]); setSelectedPrinter([]); setPrinterList(null); setSearchTerm("")}}>Atcelt</p>
                                    {Array.from(new Set(searchResults.map((item) => item.MENU_2)))
                                        .sort((a, b) => a.localeCompare(b))
                                        .map((menu1) => (
                                            <div key={menu1}>
                                                <p className="search-headers-cartridges">{menu1}</p>
                                                <ul className="search-results-cartridges">
                                                    {searchResults
                                                        .filter((item) => item.MENU_2 === menu1)
                                                        .map((item) => (
                                                            <Link
                                                                to={
                                                                    item.MENU_3 === 'N/A'
                                                                        ? `/categories/${encodeURIComponent(item.MENU_1).replace(
                                                                            /%20/g,
                                                                            '-'
                                                                        )}/${encodeURIComponent(item.MENU_2).replace(
                                                                            /%20/g,
                                                                            '-'
                                                                        )}/${item.SKU}`
                                                                        : `/categories/${encodeURIComponent(item.MENU_1).replace(
                                                                            /%20/g,
                                                                            '-'
                                                                        )}/${encodeURIComponent(item.MENU_2).replace(
                                                                            /%20/g,
                                                                            '-'
                                                                        )}/${encodeURIComponent(item.MENU_3).replace(
                                                                            /%20/g,
                                                                            '-'
                                                                        )}/${item.SKU}`
                                                                }
                                                                key={item.id}
                                                                className="search-link"
                                                            >
                                                                <li key={item.id} onClick={(e) => { toggleFunction() }}>
                                                                    <img
                                                                        src={item.MENU_2 === "Officeshop ražotie kārtridži" ? '/prodImages/Small/Officeshop Cartridges (Small)/cartridges (Small).jpg' :
                                                                            item.MENU_1 === "Kopējošās lentas (ribbon)" ? '/prodImages/Small/Ribbon (Small)/ribbon (Small).jpg' :
                                                                                item.PICTURE ? `/prodImages/Small/${item.SKU} (Small)/${item.SKU} (Small).${item.PICTURE}` :
                                                                                    `/prodImages/Small/logo (Small)/logo (Small).png`}
                                                                        alt={item.NAME} />
                                                                    <p>{item.NAME}</p>
                                                                    <h4 className="price" style={{color: "black"}}>€ {item.PRICE}</h4>
                                                                </li>
                                                            </Link>
                                                        ))}
                                                </ul>
                                            </div>
                                        ))}
                                    <div className='CartridgeSearch-results-footer'>
                                        <button className='logIn' onClick={showFullSearch} >Parādīt visus</button>
                                    </div>
                                </div>
                            )}
                        </>
                    )}

                    {window.innerWidth > 768 && (
                        <>
                            <div className='izvelne' onClick={brandActive}>{brand?.length > 0 ? brand : "Izvēlieties printera ražotāju"}</div>
                            {allBrands.map((brand) => (
                                <div
                                    value={brand}
                                    className={`saraksts ${brandIsActive ? 'active' : ''}`}
                                    onClick={(e) => {
                                        setBrand(e.target.getAttribute('value'));
                                        brandActive();
                                    }}
                                >
                                    {brand}
                                </div>
                            ))}
                            {printerList ? (
                                <>
                                    <div className='izvelne' onClick={modelActive}> {selectedPrinter?.length > 0 ? selectedPrinter : "Izvēlieties modeli"}</div>
                                    <div className='saraksta-konteiners'>
                                        <input type="text" id='myInput' className={`mekletajs ${modelIsActive ? 'active' : ''}`} placeholder={"Meklēt printeri no saraksta"} onChange={handleSearch} />
                                        {userSearch ? (
                                            userSearch.map((brand) => (
                                                <div
                                                    value={brand}
                                                    className={`saraksts ${modelIsActive ? 'active' : ''}`}
                                                    onClick={(e) => {
                                                        setSelectedPrinter(e.target.getAttribute('value'));
                                                        modelActive();
                                                    }}
                                                >
                                                    {brand}
                                                </div>
                                            ))) :
                                            printerList.map((brand) => (
                                                <div
                                                    value={brand}
                                                    className={`saraksts ${modelIsActive ? 'active' : ''}`}
                                                    onClick={(e) => {
                                                        setSelectedPrinter(e.target.getAttribute('value'));
                                                        modelActive();
                                                    }}
                                                >
                                                    {brand}
                                                </div>
                                            ))
                                        }
                                    </div>

                                </>
                            ) : null}
                            {showNotificaton &&
                            <>
                            <p className='FoundProductCount' style={{textAlign: "center"}}>Diemžēl kārtridži nav atrasti, taču mums tie noteikti ir. Aizpildi savu kontaktinformāciju un mēs sagatavosim kārtridžu piedāvājumu Tavam printerim</p>
                            <InputFormCartridgeSearch printer={selectedPrinter}/>
                            </>
                            }
                            {searchResults.length > 0 && (
                                <div>
                                    <p className='PrinterNameSearchResults' style={{ marginBottom: "0px" }}>Kārtridži <strong>{selectedPrinter}</strong> printerim</p>
                                    <span className='FoundProductCount'>Atrastas preces: {searchResults.length}</span>
                                    {location.pathname==='/cartridges' && <p className='calncelSearchText' onClick={(e) => { setSearchResults([]); setSelectedPrinter([]); setPrinterList(null); setSearchTerm(""); setBrand([]) }}>Atcelt</p>}
                                    {Array.from(new Set(searchResults.map((item) => item.MENU_2)))
                                        .sort((a, b) => a.localeCompare(b))
                                        .map((menu1) => (
                                            <div key={menu1}>
                                                <p className="search-headers-cartridges">{menu1}</p>
                                                <ul className="search-results-cartridges">
                                                    {searchResults
                                                        .filter((item) => item.MENU_2 === menu1)
                                                        .map((item) => (
                                                            <Link
                                                                to={
                                                                    item.MENU_3 === 'N/A'
                                                                        ? `/categories/${encodeURIComponent(item.MENU_1).replace(
                                                                            /%20/g,
                                                                            '-'
                                                                        )}/${encodeURIComponent(item.MENU_2).replace(
                                                                            /%20/g,
                                                                            '-'
                                                                        )}/${item.SKU}`
                                                                        : `/categories/${encodeURIComponent(item.MENU_1).replace(
                                                                            /%20/g,
                                                                            '-'
                                                                        )}/${encodeURIComponent(item.MENU_2).replace(
                                                                            /%20/g,
                                                                            '-'
                                                                        )}/${encodeURIComponent(item.MENU_3).replace(
                                                                            /%20/g,
                                                                            '-'
                                                                        )}/${item.SKU}`
                                                                }
                                                                key={item.id}
                                                                className="search-link"
                                                            >
                                                                <li key={item.id}
                                                                title={`${item.QTY > 0 ? `Noliktavā ${item.QTY> 10 ? `virs 10gb` : `${item.QTY}`}` : `${item.ExtraStockQTY !== null ? `${item.ExtraStockQTY} pieejami ${item.ExtraStockDate}` : 'piegādes laiks līdz 14 dienām'}` }`}>
                                                                    <img
                                                                        src={item.MENU_2 === "Officeshop ražotie kārtridži" ? '/prodImages/Small/Officeshop Cartridges (Small)/cartridges (Small).jpg' :
                                                                            item.MENU_1 === "Kopējošās lentas (ribbon)" ? '/prodImages/Small/Ribbon (Small)/ribbon (Small).jpg' :
                                                                                item.PICTURE ? `/prodImages/Small/${item.SKU} (Small)/${item.SKU} (Small).${item.PICTURE}` :
                                                                                    `/prodImages/Small/logo (Small)/logo (Small).png`}
                                                                        alt={item.NAME} />
                                                                    <p>{item.NAME}</p>
                                                                    <h4 className="price" style={{color: "black"}}>€ {item.PRICE}</h4>
                                                                </li>
                                                            </Link>
                                                        ))}
                                                </ul>
                                            </div>
                                        ))}
                                    <div className='CartridgeSearch-results-footer'>
                                        <button className='logIn' onClick={showFullSearch} >Parādīt visus</button>
                                    </div>
                                </div>
                            )}
                        </>
                    )}
                </div>

            </div>
        </>
    );
}

export default CartridgeSearch;
