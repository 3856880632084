import React from 'react';
import { Helmet } from 'react-helmet';
import '../App.css';

export default function Utilizacija() {
    

    
    
    return (
        <>
            <Helmet>
                <title>Kārtridžu utilizācija | Officeshop.lv </title>
                <meta name="description" content="Printeru kārtridžu utilizācija ir svarīga videi. Mūsu kārtridžu utilizācijas pakalpojumi nodrošina, ka jūsu izlietotie kārtridži tiks iznīcinātas gan jums, gan videi nekaitīgā veidā." />
                <meta name="robots" content="index, follow" />
                <meta name="keywords" content="Kārtridžu utilizācija, printeru kārtridžu utilizācija, izlietotie kārtridži, kārtridžu pieņemšana, kārtridžu nodošana, kārtridžu utilizācijas pakalpojums, biroja tehnikas utilizācija, kārtridžu pārstrāde" />
                <meta property="og:title" content="Kārtridžu utilizācija | Officeshop.lv" />
                <meta property="og:description" content="OfficeShop.lv piedāvā atbildīgu printeru kārtridžu utilizāciju, aizsargājot vidi." />
                <meta property="og:url" content="https://www.officeshop.lv/utilizacija" />
                <meta property="og:image" content="https://www.officeshop.lv/prodImages/logo.png" />
                <meta property="og:type" content="website" />
                <link rel="canonical" href="https://www.officeshop.lv/utilizacija" />
            </Helmet>
            <img src='/images/utilizacija.jpg' alt='Kārtridžu utilizācija | Officeshop.lv' style={{width: "100%"}} />            
        </>

    );
}