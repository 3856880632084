import React, { useState, useEffect } from 'react';
import { Link, useParams, useLocation, useNavigate } from 'react-router-dom';
import { GetItems } from '..//ProdHandleLogic.js';
import ProductCard from '..//productCard/ProductCard.js';
import ProductPage from '../../Pages/product.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Breadcrumb, Pagination } from 'react-bootstrap/';
import './categories.css';
import useFirebase from "../../firebase/usefirebase";
import { onAuthStateChanged } from "firebase/auth";
import { Helmet } from 'react-helmet';
import Mobile from '../Reklama/Sides/Mobile.js';





const Categories = () => {
  const { menu1, menu2, menu3, product } = useParams();
  
  let menu1Dec = menu1 ? decodeURIComponent(menu1.replace(/-/g, ' ')) : null;
  let menu2Dec = menu2 ? decodeURIComponent(menu2.replace(/-/g, ' ')) : null;
  let menu3Dec = menu3 ? decodeURIComponent(menu3.replace(/-/g, ' ')) : null;
  
  let productDec = product ? product : null;

  const [menuItems, setMenuItems] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [allProducts, setAllProducts] = useState([]);
  const [selectedItem, setSelectedItem] = useState([]);
  const [favorites, setFavorites] = useState([]);
  const [sort, setSort] = useState('');
  const [brands, setBrands] = useState([]);
  const [selectedBrands, setSelectedBrands] = useState([]);
  const [email, setEmail] = useState("");
  const [showInStock, setShowInStock] = useState(false);  
  const [specImage, setSpecImage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [authLoading, setAuthLoading] = useState(true);
  const [productsPerPage, setProductsPerPage] = useState(15);
  const [productKeywords, setProductKeywords] = useState(false);
  const [categoryKeywords, setCategoryKeywords] = useState(false);
  const [showMobileAdd, setShowMobileAdd] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const query = new URLSearchParams(location.search);
  const currentPage = parseInt(query.get('page') || '1', 10);
  const totalPages = Math.ceil(selectedProducts.length / productsPerPage);
  const maxPagesToShow = 6;
  const REACT_APP_API_SERVER = process.env.REACT_APP_API_SERVER;

  const { auth, user } = useFirebase();
  useEffect(() => {
    const updateProductsPerPage = () => {
      if (window.innerWidth < 768) {
        setProductsPerPage(16);
        setShowMobileAdd(true);
      } else {
        setProductsPerPage(15);
        setShowMobileAdd(false);
      }
    };
    updateProductsPerPage();
    window.addEventListener('resize', updateProductsPerPage);
    window.scrollTo(0, 0);
    if (auth) {
      const unsubscribe = onAuthStateChanged(auth, (user) => {
        if (user) {
          setEmail(user.email);
          fetch(`${REACT_APP_API_SERVER}/favorites?Email=${user.email}`)
            .then((response) => {
              if (!response.ok) {
                throw new Error("Failed to fetch user data");
              }
              return response.json();
            })
            .then((data) => {
              const favoritesString = data[0]?.Favorites || '';
              const favoritesArray = favoritesString.split(",").filter(item => item.trim() !== '');
              setFavorites(favoritesArray);
            })
            .catch((error) => console.error(error))
            .finally(() => setAuthLoading(false));
        } else {
          setAuthLoading(false);
        }
      });

      return () => {
        window.removeEventListener('resize', updateProductsPerPage);
        unsubscribe();
      };
    } else {
      setAuthLoading(false);
    }
  }, [REACT_APP_API_SERVER, auth]);

  useEffect(() => {
    if (menu2Dec === "Officeshop ražotie kārtridži" && menu3Dec) {
      setSpecImage(`/prodImages/Small/Officeshop Cartridges (Small)/cartridges (Small).jpg`);
    }
    if (menu1Dec === "Kopējošās lentas (ribbon)" && menu2Dec) {
      setSpecImage(`/prodImages/Small/Ribbon (Small)/ribbon (Small).jpg`);
    }
  }, [menu1Dec, menu2Dec]);

  

  useEffect(() => {
    if (authLoading) return;
    let timerId = setTimeout(() => {
      setIsLoading(true);
    }, 500);    
    GetItems(menu1Dec, menu2Dec, menu3Dec, productDec)
      .then((data) => {        
        clearTimeout(timerId);
        if ('item' in data) {
          setSelectedItem(data.item);
          setSelectedProducts([]);
        } else if ('products' in data) {          
          let filteredProducts = data.products;          
          setIsLoading(false);
          setSelectedProducts(filteredProducts);
          setAllProducts(filteredProducts);
          setBrands([...new Set(data.products.map(item => item.BRAND))]);          
        } else {
          setIsLoading(false);  
          setMenuItems(data.menu);                  
        }
        
      })
      .catch((error) => {
        console.error(error);
      });
  }, [authLoading, menu1Dec, menu2Dec, menu3Dec, productDec]);

  useEffect(() => {
    let filteredProducts = allProducts;
          if (showInStock === true) {
            filteredProducts = filteredProducts.filter(product => product.QTY > 0);
          }
          if (selectedBrands.length > 0) {
            filteredProducts = filteredProducts.filter(product => selectedBrands.includes(product.BRAND));
          }
          if (sort) {
            filteredProducts = sortProductsByPrice(filteredProducts, sort);
          }
          setSelectedProducts(filteredProducts);
  }, [showInStock, selectedBrands, sort]);

  const updateFavoritesAfterDelete = () => {
    fetch(`${REACT_APP_API_SERVER}/favorites?Email=${email}`)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to fetch user data");
        }
        return response.json();
      })
      .then((data) => {
        const favoritesString = data[0].Favorites;
        const favoritesArray = favoritesString.split(",");
        setFavorites(favoritesArray);
      })
      .catch((error) => console.error(error));
  };


  const sortProductsByPrice = (products, order) => {
    const sortedProducts = [...products].sort((a, b) => {
      const priceA = parseFloat(a.PRICE);
      const priceB = parseFloat(b.PRICE);
      if (order === 'asc') {
        return priceA - priceB;
      } else if (order === 'desc') {
        return priceB - priceA;
      } else {
        return products;
      }
    });
    return sortedProducts;
  };


  const handleSortChange = (event) => {
    setSort(event.target.value);
  };

  const handleInStockChange = (event) => {
    setShowInStock(event.target.checked);
    paginate(1);
  };

  const handleBrandSelection = (event) => {
    const { value } = event.target;
    const brandIndex = selectedBrands.indexOf(value);
    if (brandIndex === -1) {      
      setSelectedBrands([...selectedBrands, value]);      
    } else {
      const updatedBrands = [...selectedBrands];
      updatedBrands.splice(brandIndex, 1);      
      setSelectedBrands(updatedBrands);     
    }
    paginate(1);
  };






  const indexOfLastProduct = currentPage * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
  const currentProducts = selectedProducts.slice(
    indexOfFirstProduct,
    indexOfLastProduct
  );

  const paginate = (pageNumber) => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
    query.set('page', pageNumber);
    navigate({ search: query.toString() });
  }

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    if (!query.get('page') && selectedProducts.length > 0) {
      paginate(1);
    }
  }, []);

  let startPage = Math.max(1, currentPage - Math.floor(maxPagesToShow / 2));
  let endPage = startPage + maxPagesToShow - 1;

  const renderPaginationItems = () => {
    const items = [];
    let startPage = Math.max(1, currentPage - Math.floor(maxPagesToShow / 2));
    let endPage = startPage + maxPagesToShow - 1;

    if (endPage > totalPages) {
      endPage = totalPages;
      startPage = Math.max(1, endPage - maxPagesToShow + 1);
    }

    for (let page = startPage; page <= endPage; page++) {
      items.push(
        <Pagination.Item
          key={page}
          active={page === currentPage}
          onClick={() => paginate(page)}
        >
          {page}
        </Pagination.Item>
      );
    }

    return items;
  };

  //Izlasam pirmos četru vārdus no produkta nosaukuma
  function shortenKeyword(productName) {
    const words = productName.split(' ');
    const keywordArray = words.slice(0, 4);
    const keyword = keywordArray.join(' ');
    return keyword;
  }



  useEffect(() => {
    if (currentProducts.length > 0) {
      const keywords = currentProducts
        .filter(product => product.NAME !== null)
        .map(product => shortenKeyword(product.NAME))
        .join(', ');
      setProductKeywords(keywords);
    }
  }, [currentProducts]);

  useEffect(() => {
    if (menuItems.length > 0) {
      const keywords = menuItems.map(item => shortenKeyword(item.MENU_1 ? item.MENU_1 : item.MENU_2 ? item.MENU_2 : item.MENU_3 ? item.MENU_3 : null)).join(', ');
      setCategoryKeywords(keywords);
    }
  }, [menuItems]);

  const getNextDayDate = () => {
    const now = new Date();
    const nextDay = new Date(now);
    nextDay.setDate(now.getDate() + 1); 
    nextDay.setHours(23, 59, 0); 
    return nextDay.toISOString().split('T')[0] + 'T23:59:00'; 
  };

  let jsonLD;
  if (selectedProducts.length > 0) {
    jsonLD = {
      "@context": "https://schema.org",
      "@type": "ItemList",
      "name": `${menu3Dec !== null ? menu3Dec : menu2Dec !== null ? menu2Dec : menu1Dec !== null ? menu1Dec : null} - OfficeShop.lv`,
      "itemListElement":
        selectedProducts.map((product, index) => ({        
        "@type": "Product",
        "name": product.NAME,
        "image": menu2Dec === "Officeshop ražotie kārtridži" ? `https://www.officeshop.lv/prodImages/Small/Officeshop Cartridges (Small)/cartridges (Small).jpg` :
          menu1Dec === "Kopējošās lentas (ribbon)" ? `https://www.officeshop.lv/prodImages/Small/Ribbon (Small)/ribbon (Small).jpg` :
            product.PICTURE && product.PICTURE.startsWith('http') ? product.PICTURE : product.PICTURE ? `https://www.officeshop.lv/prodImages/Small/${product.SKU} (Small)/${product.SKU} (Small).${product.PICTURE}` : `https://www.officeshop.lv/prodImages/Small/logo (Small)/logo (Small).png`,
        "description": product.NAME + '. Pasūti tagad! Ātra piegāde un iepriecinošas cenas. Izvēlies no vairāk kā 100`000 precēm! www.OfficeShop.lv',
        "brand": product.BRAND !== null ? product.BRAND : "OfficeShop",
        "offers": {
          "@type": "Offer",
          "price": product.PRICE,
          "priceCurrency": "EUR",
          'priceValidUntil': getNextDayDate(),
          "availability": product.QTY > 0 ? "InStock" : "OutOfStock",
          "hasMerchantReturnPolicy": {
            "@type": "MerchantReturnPolicy",
            "returnPolicyCategory": "https://schema.org/ReturnAllowed",
            "applicableCountry": "LV",
            "returnPolicySeasonalOverride": "false",
            "merchantReturnDays": 14
          },
          "shippingDetails": {
            "@type": "OfferShippingDetails",
            "shippingDestination": {
              "@type": "DefinedRegion",
              "name": "Latvia"
            },
            "deliveryTime": {
              "@type": "ShippingDeliveryTime",
              "businessDays": 3
            }
          }
        },
        "url": "https://www.officeshop.lv" + location.pathname + "/" + product.SKU,      
      }))
      
    };
  } else if (menuItems.length > 0) {
    jsonLD = {
      "@context": "https://schema.org",
      "@type": "ItemList",
      "name": `${menu3Dec !== null ? menu3Dec : menu2Dec !== null ? menu2Dec : menu1Dec !== null ? menu1Dec : 'Produktu kategorijas'}`,
      "itemListElement": menuItems.map((item, index) => ({
        "@type": "ListItem",
        "position": index + 1,
        "url": `https://www.officeshop.lv${location.pathname}/${encodeURIComponent(item.MENU_3 ? item.MENU_3.replace(/ /g, '-') : item.MENU_2 ? item.MENU_2.replace(/ /g, '-') : item.MENU_1.replace(/ /g, '-'))}`,
        "name": item.MENU_3 ? item.MENU_3 : item.MENU_2 ? item.MENU_2 : item.MENU_1,
        "image": `https://www.officeshop.lv/catImages/${item.MENU_3 ? item.MENU_3 : item.MENU_2 ? item.MENU_2 : item.MENU_1}.jpg`,
        "description": `${item.MENU_3 ? item.MENU_3 : item.MENU_2 ? item.MENU_2 : item.MENU_1} plašā sortimentā, pieejams uzreiz no noliktavas. Ātra piegāde un iepriecinošas cenas. Apskati piedāvājumu jau tagad! www.OfficeShop.lv`
      }))
    };
  }
  // let Filter = [];


  // const Filter1 = [...new Set(selectedProducts.map(product => product.ATTRIBUTE_NAME1))];
  // const Filter2 = [...new Set(selectedProducts.map(product => product.ATTRIBUTE_NAME2))];
  // const Filter3 = [...new Set(selectedProducts.map(product => product.ATTRIBUTE_NAME3))];
  // const Filter4 = [...new Set(selectedProducts.map(product => product.ATTRIBUTE_NAME4))];
  // const Filter5 = [...new Set(selectedProducts.map(product => product.ATTRIBUTE_NAME5))];

  // const combinedSet = new Set([...Filter1, ...Filter2, ...Filter3, ...Filter4, ...Filter5]);

  // Filter = [...combinedSet];

  return (
    <>
      <Helmet>
        <title>{`${menu3Dec !== null ? menu3Dec : menu2Dec !== null ? menu2Dec : menu1Dec !== null ? menu1Dec : 'Produktu kategorijas'} - OfficeShop.lv`}</title>
        <meta name="description" content={`${menu3Dec !== null ? menu3Dec : menu2Dec !== null ? menu2Dec : menu1Dec !== null ? menu1Dec : 'Izvēlies no vairāk kā 100`000 precēm'} - atrodi sev nepieciešamo | OfficeShop.lv`} />
        <meta name="robots" content="index, follow" />
        <meta name="keywords" content={productKeywords ? productKeywords : categoryKeywords} />
        <meta property="og:title" content={`${menu3Dec !== null ? menu3Dec : menu2Dec !== null ? menu2Dec : menu1Dec !== null ? menu1Dec : 'Biroja, skolas un elektro preces'} - OfficeShop.lv`} />
        <meta property="og:description" content={`${menu3Dec !== null ? menu3Dec : menu2Dec !== null ? menu2Dec : menu1Dec !== null ? menu1Dec : 'Izvēlies no vairāk kā 100`000 precēm'} - atrodi sev nepieciešamo | OfficeShop.lv`} />
        <meta property="og:url" content={`https://www.officeshop.lv${location.pathname}`} />
        <link rel="canonical" href={`https://www.officeshop.lv${location.pathname}`} />
        <meta property="og:image" content={`${menu3Dec !== null ? `https://www.officeshop.lv/catImages/${menu3Dec}.jpg` : menu2Dec !== null ? `https://www.officeshop.lv/catImages/${menu2Dec}.jpg` : menu1Dec !== null ? `https://www.officeshop.lv/catImages/${menu1Dec}.jpg` : `https://www.officeshop.lv/prodImages/logo.png`} `} />

        <meta property="og:image:alt" content={`${menu3Dec !== null ? menu3Dec : menu2Dec !== null ? menu2Dec : menu1Dec !== null ? menu1Dec : 'Produktu kategorijas'} - OfficeShop.lv`} />
        <meta property="og:type" content="website" />
        {jsonLD && <script type="application/ld+json">{JSON.stringify(jsonLD)}</script>}
      </Helmet>
      {!isLoading && showMobileAdd && !selectedItem.length > 0 && (menuItems.length > 0 || selectedProducts.length > 0)  ? <Mobile /> : null}
      {menu1Dec || menu2Dec || menu3Dec !== null ?
        <Breadcrumb className='breadcrumb'>
          {(menu1Dec || menu2Dec || menu3Dec) !== null ? <Breadcrumb.Item><Link to={`/categories`}>Kategorijas</Link></Breadcrumb.Item> : null}
          {menu1Dec !== null ? <Breadcrumb.Item><Link className={menu2Dec === null ? 'active' : null} to={`/categories/${encodeURIComponent(menu1)}`}>
            {menu1Dec.split(' ').length > 2 && window.innerWidth < 768
              ? `${menu1Dec.split(' ').slice(0, 2).join(' ')}...`
              : menu1Dec}
          </Link></Breadcrumb.Item> : null}
          {menu2Dec !== null ? <Breadcrumb.Item><Link className={menu3Dec === null ? 'active' : null} to={`/categories/${encodeURIComponent(menu1)}/${encodeURIComponent(menu2)}`}>
            {menu2Dec.split(' ').length > 2 && window.innerWidth < 768
              ? `${menu2Dec.split(' ').slice(0, 2).join(' ')}...`
              : menu2Dec}
          </Link></Breadcrumb.Item> : null}
          {menu3Dec !== null ? <Breadcrumb.Item><Link className='active' to={`/categories/${encodeURIComponent(menu1)}/${encodeURIComponent(menu2)}/${encodeURIComponent(menu3)}`}>
            {menu3Dec}
          </Link></Breadcrumb.Item> : null}
        </Breadcrumb>
        : null}
      {isLoading && (menuItems.length < 0 || selectedProducts.length < 0 ) ? <div className='product-page'>
        <img src='/images/loading.gif' alt='loading' />
      </div> : 
      menuItems.length > 0  && !isLoading ? (
        <div className="product-page">  
          {menuItems.map((item, index) => (            
            <div className="product">
              <div className="category-card">
                <Link
                  key={index}
                  to={
                    item.MENU_3
                      ? `/categories/${encodeURIComponent(menu1Dec).replace(
                        /%20/g,
                        '-'
                      )}/${encodeURIComponent(menu2Dec).replace(
                        /%20/g,
                        '-'
                      )}/${encodeURIComponent(item.MENU_3).replace(
                        /%20/g,
                        '-'
                      )}`
                      : item.MENU_2
                        ? `/categories/${encodeURIComponent(menu1Dec).replace(
                          /%20/g,
                          '-'
                        )}/${encodeURIComponent(item.MENU_2).replace(
                          /%20/g,
                          '-'
                        )}`
                        : item.MENU_1
                          ? `/categories/${encodeURIComponent(item.MENU_1).replace(
                            /%20/g,
                            '-'
                          )}`
                          : null
                  }
                  className="product-header"
                  onClick={scrollToTop}
                >
                  <div className='image-container'>                                    
                    <img src={`/catImages/` + (item.MENU_1 ? item.MENU_1 : item.MENU_2 ? item.MENU_2 : item.MENU_3 ? item.MENU_3 : null) + `.jpg`} alt={item.MENU_1 ? item.MENU_1 : item.MENU_2 ? item.MENU_2 : item.MENU_3 ? item.MENU_3 : null} />
                  </div>
                  <div className="product-header">
                    {item.MENU_1 ? item.MENU_1 : item.MENU_2 ? item.MENU_2 : item.MENU_3 ? item.MENU_3 : null}
                  </div>
                </Link>
              </div>
            </div>
          ))}
        </div>
      ) : selectedProducts.length > 0  && !isLoading ? (
        <>
          <div className='above-sorting'>
            <div className='sortingContainerShow'>
              <h6 className='sortingTitle'>Cena</h6>
              <select onChange={handleSortChange}>
                <option value=''>↑ ↓</option>
                <option value='desc'>Cena ↑</option>
                <option value='asc'>Cena ↓</option>
              </select>
            </div>

            <div className='sorting'>
              <h6 className='sortingTitle'>Zīmoli</h6>
              <div className='sorting-container'>
                <div className='section'>
                  <div className='brands'>
                    {brands.sort((a, b) => a.localeCompare(b)).map((brand, index) => (
                      <div key={index}>
                        <input type='checkbox' id={brand} name={brand} value={brand} onChange={handleBrandSelection} />
                        <label htmlFor={brand}>{brand}</label>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <div className='sortingContainerShow'>
              <h6 className='sortingTitle'>Preces pieejamība</h6>
              <input className='stock' type='checkbox' id='inStock' name='inStock' value='inStock' onChange={handleInStockChange} />
              <label htmlFor='inStock'></label>
            </div>
          </div>
          <div className="product-page">
            {currentProducts.map((item, index) => (              
              <div key={index} className="product">
                <ProductCard
                  name={item.NAME}
                  price={item.PRICE}
                  quantity={item.QTY}                  
                  minorder={item.MINORDER}
                  discount={item.DISCOUNT}
                  link={`./${item.SKU}`}
                  image={specImage ? specImage : item.PICTURE && item.PICTURE.startsWith('http') ? item.PICTURE : item.PICTURE ? `/prodImages/Small/${item.SKU} (Small)/${item.SKU} (Small).${item.PICTURE}` : `/prodImages/Small/logo (Small)/logo (Small).png`}
                  sku={item.SKU}
                  fav={favorites.includes(item.SKU) ? 'yes' : 'no'}
                  onDeleteFav={() => { updateFavoritesAfterDelete(); }}
                  userEmail={email}
                />
              </div>
            ))}
          </div>
          {selectedProducts.length > productsPerPage ? (
            <Pagination className='custom-pagination'>
              <Pagination.First onClick={() => currentPage === 1 ? null : paginate(1)} />
              <Pagination.Prev onClick={() => currentPage === 1 ? null : paginate(currentPage - 1)} />
              {startPage > 1 && <Pagination.Ellipsis onClick={() => paginate(startPage - 1)} />}
              {renderPaginationItems()}
              {endPage < totalPages && <Pagination.Ellipsis onClick={() => paginate(endPage + 1)} />}
              <Pagination.Next onClick={() => currentPage === totalPages ? null : paginate(currentPage + 1)} />
              <Pagination.Last onClick={() => currentPage === totalPages ? null : paginate(totalPages)} />
            </Pagination>
          ) : null}
        </>
      ) : selectedItem.length > 0 && ! isLoading ? (
        <div>
          <ProductPage item={selectedItem} />
        </div>
      ) :
        <div className='product-page'>
        </div>
      }
    </>
  );
};

export default Categories;
