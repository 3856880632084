import React from 'react';
import { Helmet } from 'react-helmet';
import './terms.css';

export default function Terms() {
    const Terms = `
    <style>
        .policy-container p, ul {
            font-size: 14px;
            line-height: 1.2;
            color: #333;
            margin-bottom: 2px;
            margin-top: 0;
            padding-left: 15px;
        }
        .policy-container p {
            text-align: justify;
            
        }
        .policy-container strong {
            color: #000;
        }
        .policy-container ul {
            padding-left: 15px;
        }
        .policy-container li {
            margin-bottom: 2px;   

        }
        
        .policy-container p strong {
            display: block;
            margin-bottom: 3px;
            font-size: 16px;
            font-weight: bold;  
            margin-left: -15px;
        }
        
        .policy-container ul {
            list-style: square;
            margin: 0;
            padding-left: 18px;
        }
        
        .policy-container {
            padding: 5px;            
        }
        .policy-container .header {
            text-align: center; 
            font-size: 18px;
            font-weight: bold;
            margin-bottom: 20px;     
        }     
    </style>

    <div class="policy-container">
    
    <p class="header"><strong>INTERNETA VEIKALA MĀJAS LAPAS OfficeShop.lv LIETOŠANAS NOTEIKUMI</strong></p><p> SR Logistics, Reģistrācijas Nr. 40203462776, turpmāk – internetveikals www.OfficeShop.lv – nodrošina mājas lapā pieejamo saturu un sniedz preces/pakalpojumus saskaņā ar zemāk izklāstītajiem Lietošanas noteikumiem.</p>

    <p><strong>1. Vispārīgie noteikumi</strong></p>
    <p>Ja patērētājs, izmantojot mājas lapas starpniecību iegādājas preces/pakalpojumus, tad šāda savstarpēja vienošanās tiek uzskatīta par Distances līgumu un ir pakļauta Latvijas Republikas tiesību normām, kas regulē distances līgumu tai skaitā, bet ne tikai, Latvijas Republikas „Patērētāju tiesību aizsardzības likums”, Latvijas Republikas Ministru Kabineta noteikumiem „Noteikumi par distances līgumu” u.c.</p>

    <p><strong>2. Pirkumu veikšana</strong></p>
    <p>Interneta veikalā pārdoto produktu cenas un specifikācija ir norādītas blakus produktiem.</p>
    <p>Lai veiktu pasūtījumu, pievienojiet vēlamos produktus iepirkumu grozam. Aizpildiet visus nepieciešamos laukus un izvēlēties piemērotāko piegādes veidu. Pasūtījuma kopējās izmaksas ar piegādi pēc tam tiek parādītas uz ekrāna. Veiciet pirkuma apmaksu, lai pabeigtu pasūtījumu.</p>

    <p><strong>3. Apmaksas noteikumi</strong></p>
    <p>Norēķinu valūta vietnē ir eiro. Pirkumu iespējams apmaksāt izvēloties sekojošas maksājumu metodes:</p>
    <ul>
        <p>Maksājums uz Sia SR Logistics bankas kontu atbilstoši priekšapmaksas rēķinam</p>
        <p>Internetbankas pārskaitījums (Klix maksājumi)</p>        
    </ul>

    <p><strong>5. Atteikuma tiesības</strong></p>
    <p>Pircējam ir tiesības atteikties no preces 14 kalendāro dienu laikā no Preces saņemšanas brīža. Atteikuma tiesības nav piemērojamas, ja Pircējs ir juridiska persona.</p>
    <p>Lai izmantotu 14 dienu atteikuma tiesības, preces drīkst izmantot tikai tām paredzētajiem mērķiem, patērētājs ir atbildīgs par preces kvalitātes un drošuma saglabāšanu atteikuma tiesību realizēšanas termiņā. Ja preces tiek nepiemēroti izmantotas vai bojātas, lietošanas laikā nevērīgi izturoties pret preci vai neievērojot instrukcijas norādījumus, ja ir nozaudēts preces oriģinālais iepakojums, vai, ja tās iepakojums ir būtiski bojāts, internetveikalam ir tiesības samazināt atmaksājamo summu atbilstoši preces vērtības samazinājumam.</p>
    <p>Atgriezt nevar preces, kas nav piemērotas atgriešanai higiēnas vai veselības apsvērumu dēļ, vai arī personalizētas preces.</p>
    <p>Lai izmantotu atteikuma tiesības, Jums jāiesniedz atteikuma veidlapu uz e-pasta adresi info@officeshop.lv 14 dienu laikā pēc preču saņemšanas.</p>
    <p>Pircējs sedz preces atgriešanas izmaksas, izņemot gadījumus, kad iemesls atgriešanai ir tas, ka prece neatbilst pasūtījumam (piemēram, nepareizs vai bojāts produkts).</p>
    <p>Pircēja pienākums ir bez kavēšanās, bet ne vēlāk kā 14 dienu laikā pēc atteikuma veidlapas nosūtīšanas internetveikalam, atdot Pārdevējam Preces. Pēc atpakaļ nosūtīto preču saņemšanas internetveikals nekavējoties, bet ne vēlāk kā 14 dienu laikā, atmaksā visus no pircēja saņemtos maksājumus pamatojoties uz Distances līgumu.</p>
    <p>Pārdevējam ir tiesības aizturēt maksājumu līdz brīdim, kamēr nav saņēmis no Pircēja Preces vai preču atgriešanas apliecinājumu. Pēc preču saņemšanas no Pircēja, Pārdevējs veick naudas atmaksu 14 dienu laikā no datuma, kad prece saņemta no Pircēja. Nauda tiek atmaksāta uz to pašu maksāšanas līdzekli, kas tika izmantots pirkuma laikā vai Pircēja norādītu bankas kontu, kur saņēmējs ir konkrētais Pircējs. Ja pircējs ir izvēlējies citu sūtīšanas veidu nekā lētāko internetveikalā piedāvāto sūtījumu veidu, internetveikalam nav pienākums kompensēt pārsniegtās sūtījuma izmaksas.</p>
    <p>Internetveikals nav atbildīgs par kavēšanos ar saistību izpildi vai to nepildīšanu, vai cita veida neizpildi, kas radusies tādu apstākļu un šķēršļu dēļ, kuri ir ārpus internetveikala saprātīgas kontroles.</p>
    <p>Internetveikals patur tiesības atteikties pārdot preces un pieprasīt preču atgriešanu no Pircēja, ja internetveikalā norādītā cena ir ievērojami mazāka par tās tirgus cenu kļūdas dēļ.</p>
    <p>Pasūtījumu var atcelt vienas dienas laikā pēc apmaksas veikšanas, sazinoties ar klientu atbalsta dienestu.</p>

    <p><strong>6. Patērētāja tiesības par līgumam neatbilstošu preci</strong></p>
    <p>Internetveikals ir atbildīgs par Pircējam pārdoto preču neatbilstību līguma noteikumiem vai trūkumiem kas radusies 6 mēnešu laikā pēc preču piegādes dienas klientam, vai bijusi piegādes brīdī, ja šāds pieņēmums nav pretrunā ar lietas īpašībām vai trūkumu. Pircējam ir jāinformē Pārdevējs par preču neatbilstību nekavējoties jāiesniedz sūdzība. Pircējs var iesniegt sūdzību, sazinoties ar internetveikalu, rakstot uz info@officeshop.lv.</p>
    <p>Internetveikals nav atbildīgs par defektiem, kas radušies pēc preču piegādes Pircējam. Ja iegādātajām precēm ir trūkumi, par kuriem ir atbildīgs Pārdevējs, Pircējam ir tiesības pieprasīt preču trūkumu novēršanu vai apmaiņu pret jaunām precēm bez maksas.</p>
    <p>Ja preces nav iespējams labot vai nomainīt, Pārdevējs atdod Pircējam visus Distances līgumā paredzētos maksājumus. Pārdevējs uz patērētāja sūdzību sniedz rakstisku atbildi 15 dienu laikā.</p>

    <p><strong>7. Pircēja personas datu apstrāde</strong></p>
    <p>Internetveikals apstrādā tikai tos personas datus, kurus pircējs ir ievadījis pie preču pasūtīšanas, kā piemēram, vārds, uzvārds, e-pasts u.c.</p>
    <p>Internetveikals nodod personas datus transporta pakalpojumu sniedzējam/iem, lai nodrošinātu preču piegādi.</p>
    <p>Ja Jūs esat nepārprotami piekritis saņemt mūsu mārketinga paziņojumus, tostarp jaunumus, mēs laiku pa laikam varam sazināties ar Jums nosūtot informāciju par mūsu pakalpojumiem un jaunākajiem piedāvājumiem. Šajā nolūkā mēs varam apstrādāt Jūsu e-pasta adresi, kuru norādījāt, pierakstoties mārketinga komunikācijai. Pircējam ir iespēja atteikties no mārketinga paziņojumiem, paziņojot par to mums, rakstot uz info@officeshop.lv.</p>

    <p><strong>8. Strīdu izšķiršanas kārtība</strong></p>
    <p>Jautājumos, kas nav atrunāti šajos noteikumos un nosacījumos, Pircējs un Pārdevējs apņemas ievērot Latvijas Republikā spēkā esošos normatīvos aktus.</p>
    <p>Puses visus starp Pārdevēju un Pircēju radušos strīdus risina savstaprēju sarunu vai sarakstes ceļā. Ja strīdu neizdosies atrisinās sarunu vai sarakstes veidā, Puses strīdu risinās Latvijas Republikas tiesā, ievērojot LR normatīvos aktus. Strīdus starp Pircēju (patērētāju) un Pārdevēju var nodot risināšanai Patērētāju tiesību aizsardzības centrā vai Latvijas Republikas tiesā.</p>
    <p>Pircējam ir tiesības vērsties arī Eiropas Savienības strīdu izšķiršanas iestādēs.</p>
</div>`;

    
    
    return (
        <>
            <Helmet>
                <title>Lietošanas noteikumi | Officeshop.lv </title>
                <meta name="description" content="OfficeShop.lv lietošanas noteikumi" />
                <meta name="robots" content="index, follow" />
                <meta name="keywords" content="OfficeShop.lv, kārtridži, printeru kārtridži, ofisa preces" />
                <meta property="og:title" content="Lietošanas noteikumi | Officeshop.lv" />
                <meta property="og:description" content="OfficeShop.lv piedāvā atbildīgu printeru kārtridžu utilizāciju, aizsargājot vidi." />
                <meta property="og:url" content="https://www.officeshop.lv/terms" />
                <meta property="og:image" content="https://www.officeshop.lv/prodImages/logo.png" />
                <meta property="og:type" content="website" />
                <link rel="canonical" href="https://www.officeshop.lv/terms" />
            </Helmet>
            <div className='termsContainer' >
                <p dangerouslySetInnerHTML={{ __html: Terms }}></p>
            </div>
        </>

    );
}